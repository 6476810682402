import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  VStack,
  Text,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useContext,
  createContext,
  useState,
  useMemo,
} from "react";

const DEFAULT_DIALOG_PROPS: {
  isOpen: boolean;
  title: string;
  text: string;
  actions: ActionType[];
} = {
  isOpen: false,
  title: "",
  text: "",
  actions: [{ type: "confirm", text: "Got it", props: {} }],
};

type ActionType = {
  type: string;
  text: string;
  onClick?: () => void;
  props?: object;
};

export const createDialogContext = (otherProps: object = {}) => {
  return createContext({
    ...DEFAULT_DIALOG_PROPS,
    open: (_params: {
      title?: string;
      text: string;
      actions?: ActionType[];
    }) => {},
    close: () => {},
    ...otherProps,
  });
};

export const DialogContext = createDialogContext();

export const useDialogWithOptions = (
  context: React.Context<{
    isOpen: boolean;
    open: (params?: {
      title?: string;
      text?: string;
      actions?: ActionType[];
    }) => any;
    close: (params?: any) => any;
    text?: string;
    title?: string;
    actions: ActionType[];
  }> = DialogContext
) => {
  const dialog = useContext(context);

  return { open: dialog.open, close: dialog.close, isOpen: dialog.isOpen };
};

export const useDialog = (
  context: React.Context<{
    isOpen: boolean;
    open: (params: {
      title?: string;
      text?: string;
      actions?: ActionType[];
    }) => any;
    close: (params: any) => any;
    text?: string;
    title?: string;
    actions: ActionType[];
  }> = DialogContext
) => {
  const dialog = useContext(context);
  return dialog.open;
};

export const DialogProvider: React.FC<any> = ({
  children,
  context,
}: {
  children: React.ReactNode;
  context: React.Context<any>;
}) => {
  const [isOpen, setIsOpen] = useState(DEFAULT_DIALOG_PROPS.isOpen);
  const [text, setText] = useState(DEFAULT_DIALOG_PROPS.text);
  const [title, setTitle] = useState(DEFAULT_DIALOG_PROPS.title);
  const [actions, setActions] = useState<ActionType[]>(
    DEFAULT_DIALOG_PROPS.actions
  );

  const open = useCallback(
    (
      {
        text,
        title,
        actions,
      }: {
        text: string;
        title: string;
        actions: ActionType[];
      } = {
        text: "",
        title: "",
        actions: [],
      }
    ) => {
      setIsOpen(true);
      setText(text);
      setTitle(title);
      setActions(actions || DEFAULT_DIALOG_PROPS.actions);
    },
    [setIsOpen, setText, setTitle]
  );

  const close = useCallback(() => {
    setIsOpen(false);
    setText(DEFAULT_DIALOG_PROPS.text);
    setTitle(DEFAULT_DIALOG_PROPS.title);
    setActions(DEFAULT_DIALOG_PROPS.actions);
  }, [setIsOpen, setText, setTitle, setActions]);

  const value = useMemo(
    () => ({
      isOpen,
      text,
      title,
      actions,
      open,
      close,
    }),
    [isOpen, text, title, actions, open, close]
  );

  const ContextToUse = context ? context.Provider : DialogContext.Provider;

  return <ContextToUse value={value}>{children}</ContextToUse>;
};

const Dialog = () => {
  const { isOpen, text, title, actions, close } = useContext(DialogContext);

  return (
    <>
      <Modal isOpen={isOpen} onClose={close} isCentered>
        <ModalOverlay />
        <ModalContent
          mb={[0, "auto"]}
          borderBottomLeftRadius={[0, "md"]}
          borderBottomRightRadius={[0, "md"]}
        >
          <ModalHeader paddingTop={2}>
            <VStack gap={0} width={"full"}>
              {title ? <Text w="full">{title}</Text> : null}
            </VStack>
          </ModalHeader>
          <ModalBody textAlign={"center"}>{text}</ModalBody>
          <ModalFooter>
            {actions.map((action) => (
              <Button
                key={action.text}
                variant="kydDark"
                w={actions.length === 1 ? "full" : "auto"}
                onClick={() => (action.onClick ? action.onClick() : close())}
                {...(action.props ? action.props : {})}
              >
                {action.text}
              </Button>
            ))}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Dialog;

export const createWithModalWrapperComponent =
  <C extends object>(
    context: React.Context<C>,
    ProviderComponent: React.FC<any> = DialogProvider
  ) =>
  <T extends object>(Component: React.ComponentType<T>) =>
  (props: T) => {
    return (
      <ProviderComponent context={context}>
        <Component {...(props as T)} />
      </ProviderComponent>
    );
  };
