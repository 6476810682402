import { KYDTicketType } from "@common/interfaces/KYDTicket";
import { Wallet } from "@common/interfaces/KYDUser";

export const ticketTypeHasWaitlistBuyOrder = (
  tt: KYDTicketType,
  wallet?: Wallet | null
) => !!wallet?.buy_waitlist_orders.find((wo) => wo?.ticket_type_id === tt.id);

export const ticketTypeHasWaitlistSellOrder = (
  tt: KYDTicketType,
  wallet?: Wallet | null
) => !!wallet?.sell_waitlist_orders.find((wo) => wo?.ticket_type_id === tt.id);

export const ticketTypeHasAnyWaitlistOrder = (
  tt: KYDTicketType,
  wallet?: Wallet | null
) =>
  ticketTypeHasWaitlistBuyOrder(tt, wallet) ||
  ticketTypeHasWaitlistSellOrder(tt, wallet);

export const isWaitlistTicketType = (tt: KYDTicketType) => {
  const retval = tt.waitlist_status === "OPEN";
  return retval;
};

export const walletHasWaitlistBuyOrder = (wallet?: Wallet | null) => {
  if (!wallet) return false;

  return wallet.buy_waitlist_orders?.length > 0;
};

export const walletHasWaitlistSellOrder = (wallet?: Wallet | null) => {
  if (!wallet) return false;

  return wallet.sell_waitlist_orders?.length > 0;
};
