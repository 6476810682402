import { Box, Image, Text, chakra } from "@chakra-ui/react";
import HighlightedTitleCard from "../HighlightedTitleCard";
import ticketIcon from "../../assets/ticket-icon-black.png";
import lockIcon from "../../assets/lock-icon-black.png";
import React from "react";

export const InlineText = chakra.span;

const WaitlistTopCard = ({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <HighlightedTitleCard
      icon={
        <>
          <Box
            p={2}
            borderWidth={2}
            borderColor="black"
            borderRadius="lg"
            height="36px"
            width="36px"
            backgroundColor="lightGreen"
          >
            <Image src={ticketIcon} width={"18px"} />
          </Box>
          <Box
            position={"absolute"}
            left={5}
            p={2}
            borderWidth={2}
            borderColor="black"
            borderRadius="lg"
            height="36px"
            width="36px"
            backgroundColor="lightGreen"
          >
            <Image src={lockIcon} width={"18px"} />
          </Box>
        </>
      }
      title={title}
    >
      {children}
    </HighlightedTitleCard>
  );
};

export default WaitlistTopCard;
