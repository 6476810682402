import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

const HighlightedTitleCard = ({
  icon,
  title,
  children,
}: {
  icon: React.ReactNode;
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <VStack backgroundColor="white" borderRadius="lg" w="full" p={2} pb={4}>
      <HStack
        w="full"
        backgroundColor="lightGreen"
        borderRadius={"lg"}
        p={2}
        backgroundImage="radial-gradient(circle, rgba(0,0,0, 0.1) 30%, lightGreen 20%);"
        backgroundSize="4px 4px"
        fontSize={22}
      >
        <HStack pos="relative" pr={9}>
          {icon}
        </HStack>
        <Text fontWeight={"bold"}>{title}</Text>
      </HStack>
      <VStack w="full" justifyContent={"flex-start"} px={4}>
        {children}
      </VStack>
    </VStack>
  );
};

export default HighlightedTitleCard;
