import { useEffect, useState } from "react";

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const usePolling = <T>({
  poll,
  checkResult = (result) => !!result,
  waitFor = 2000,
  maxRuns = 15,
}: {
  poll: () => Promise<T>;
  checkResult?: (result: T) => boolean;
  waitFor?: number;
  maxRuns?: number;
}) => {
  const [result, setResult] = useState<T | null>(null);

  async function run(numberOfRuns = 0) {
    console.log(`🏊️ usePolling - run ${numberOfRuns}`);
    const _result = await poll();

    if (numberOfRuns >= maxRuns) {
      return false;
    }

    if (!checkResult(_result)) {
      await sleep(waitFor);
      return run(numberOfRuns + 1);
    }

    setResult(_result);
    return _result;
  }

  return {
    result,
    start: () => run(),
  };
};
