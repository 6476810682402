import { Box, useBreakpointValue } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FunctionComponent, useEffect, useRef, useState } from "react";

const MotionBox = motion(Box, {
  forwardMotionProps: false,
});

/**
 * Will float in the bottom of the screen and render the {children} when {isFloating}
 * is true
 */
const FloatingBottomPanel: FunctionComponent<{
  isFloating: boolean;
  children: React.ReactNode;
}> = ({ isFloating = false, children }) => {
  const [isInView, setIsInView] = useState(false);
  const elementRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const element = elementRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          } else {
            setIsInView(false);
          }
        });
      },
      { threshold: 1 }
    ); // 100% of the element must be in view

    // Start observing the element
    if (element) {
      observer.observe(element);
    }

    // Clean up on component unmount
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  const shouldFloat = isFloating && !isInView && isMobile;

  return (
    <>
      <Box ref={elementRef} width={"full"}></Box>
      <AnimatePresence>
        {shouldFloat ? (
          <MotionBox
            key="box"
            left={0}
            position={"fixed"}
            height={20}
            bottom={0}
            boxShadow="dark-lg"
            borderTopWidth={1}
            bg="black"
            width={"full"}
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            zIndex={10}
            transition={{ type: "easeInOut", duration: 0.333, stiffness: 0 }}
          >
            <MotionBox px={4} py={4}>
              {children}
            </MotionBox>
          </MotionBox>
        ) : (
          children
        )}
      </AnimatePresence>
    </>
  );
};

export default FloatingBottomPanel;
