import React, { useCallback, useEffect, useState, useMemo } from "react";
import actions from "../actions";
import {
  Box,
  Text,
  Stack,
  Image,
  HStack,
  VStack,
  Button,
  Spinner,
  useBreakpointValue,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  KYDEvent as IKYDEvent,
  KYDEvent,
  KYDEventSummary,
  KYDPixels,
} from "@common/interfaces/KYDEvent";
import Div100vh from "react-div-100vh";

import { useNavigate } from "react-router-dom";
import logoFooter from "../lightsmall.png";
import logoFooterDark from "../darksmall.png";

import { analytics, AUTH_STATES, ModalType, PIXELS } from "../utils";
import "react-phone-input-2/lib/bootstrap.css";
import { SocialIcon } from "react-social-icons";

import {
  KYDFeed,
  KYDFeedItem,
  KYDOrganizationDisplay,
} from "@common/interfaces/KYDOrganization";
import lightsmall from "../lightsmall.png";

function KYDEmbed() {
  const [feed, setFeed] = useState<KYDFeed | null>(null);

  const [loading, setLoading] = useState<any>({});
  const [displayAllMode, setDisplayAllMode] = useState<any>({});

  const isDesktop = useBreakpointValue({ base: false, md: true });

  const updateLoadingState = useCallback(
    (key: "auth" | "wallet" | "cart" | "wallet_silent", value: boolean) => {
      setLoading((l: any) => {
        return { ...l, [key]: value };
      });
    },
    []
  );

  const updateDisplayAllMode = useCallback(
    (key: "kyd_events" | "news" | "music", value: boolean) => {
      setDisplayAllMode((l: any) => {
        return { ...l, [key]: value };
      });
    },
    []
  );

  useEffect(() => {
    const fetchKYDEvents = async () => {
      try {
        const _ = await actions.USER.fetchBio();
        analytics.t("event: fetched all upcoming events");

        if (_.organization) {
          document.title = _.organization.name;
          /*if (_.organization.pixels) {
            setupPixels(_.organization.pixels);
          }*/
        }
        setFeed(_);

        analytics.t("pageview", {
          type: "bio",
        });
      } catch (err: any) {
        //TODO: show error
        analytics.t("error: failed to load upcoming events", {
          message: err && err.message,
        });
      }
    };

    fetchKYDEvents();
  }, []);

  const buildEventLink = (link: string) => {
    if (link && window) {
      const search = window.location.search;
      return search && search.length > 1
        ? `${link}${window.location.search}`
        : link;
    }
  };

  const renderEvent = (kyd_event: KYDEventSummary, index: number) => {
    return (
      <a
        key={kyd_event.id}
        href={buildEventLink(kyd_event.link)}
        target="_blank"
        rel={"noreferrer"}
      >
        <Stack p={3} borderColor={"gray.300"}>
          <HStack h="100%">
            <Image
              src={kyd_event.image}
              w={"200px"}
              h={"200px"}
              objectFit={"scale-down"}
            />
            <Stack justifyContent={"space-between"} h="200px" spacing={0}>
              <Stack spacing={0}>
                <Text color="black" fontSize={"md"} fontWeight={"medium"}>
                  {kyd_event.display_start_at}
                </Text>
                <Text lineHeight={"1.4"} fontSize={"xl"} fontWeight="semibold">
                  {kyd_event.name}
                </Text>
              </Stack>
              <Stack spacing={-1}>
                <Text fontSize={"sm"} fontWeight={"bold"}>
                  {kyd_event.venue.name}
                </Text>

                <Text fontSize={"sm"} fontWeight={"medium"}>
                  {kyd_event.venue.city}
                </Text>
              </Stack>
            </Stack>
          </HStack>
          <Stack
            display={{ base: "none", md: "flex" }}
            justify={"center"}
            h="100%"
            spacing={0}
          >
            <HStack
              h="50px"
              w="200px"
              px={4}
              py={2}
              fontWeight={"medium"}
              _hover={{ bg: "gray.100" }}
              bg="white"
              borderColor={"black"}
              borderWidth={"1px"}
              color="black"
              textAlign={"center"}
              justify={"center"}
            >
              <Text>
                {kyd_event.display_mode === "rsvp" ? "RSVP" : "Get Tickets"}
              </Text>
            </HStack>
          </Stack>
        </Stack>
      </a>
    );
  };

  const renderFeedItem = (item: KYDFeedItem, index: number) => {
    let borderBottomWidth = "1px";
    if (
      item.type === "music" &&
      (index === (feed?.music_items.length || 0) - 1 ||
        (index === 2 && !displayAllMode.music))
    ) {
      borderBottomWidth = "0px";
    } else if (
      item.type === "news" &&
      (index === (feed?.news_items.length || 0) - 1 ||
        (index === 2 && !displayAllMode.news))
    ) {
      borderBottomWidth = "0px";
    }

    return (
      <a
        key={item.id}
        style={{ width: "100%" }}
        href={item.link}
        target="_blank"
        rel="noreferrer"
      >
        <HStack
          p={3}
          w="100%"
          minH="100px"
          borderBottom={borderBottomWidth}
          borderColor={"gray.300"}
          justify={"space-between"}
          spacing={3}
        >
          <HStack>
            <Image rounded={"md"} src={item.image} maxW={"100px"} />
            <Stack spacing={0}>
              <Text fontWeight="semibold">{item.title}</Text>
              <Text fontSize={"sm"}>{item.description}</Text>
            </Stack>
          </HStack>
          <Stack
            display={{ base: "none", md: "flex" }}
            justify={"center"}
            h="100%"
            spacing={0}
          >
            <VStack
              borderWidth={"1px"}
              borderColor={"black"}
              rounded={"md"}
              px={4}
              py={2}
              minW={"130px"}
              fontWeight={"medium"}
              _hover={{ bg: "gray.100" }}
              color="black"
            >
              <Text>
                {item.type === "music" ? "Listen Now" : "Read Article"}
              </Text>
            </VStack>
          </Stack>
        </HStack>
      </a>
    );
  };

  const renderDesktopHeader = () => (
    <Box w="100%" px={4}>
      <Box
        w="100%"
        color={"white"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Stack py={3} spacing={3}>
          <Stack spacing={-2}>
            <Text fontSize={"5xl"} fontWeight={"bold"}>
              {feed?.organization?.name}
            </Text>
            <HStack>
              <Text>{feed?.organization?.display_verified_fans}</Text>
            </HStack>
          </Stack>

          <HStack>
            {feed?.organization.socials.twitter ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.twitter.link}
              />
            ) : null}
            {feed?.organization.socials.instagram ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.instagram.link}
              />
            ) : null}
            {feed?.organization.socials.spotify ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.spotify.link}
              />
            ) : null}
            {feed?.organization.socials.applemusic ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.applemusic.link}
              />
            ) : null}
            {feed?.organization.socials.soundcloud ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.soundcloud.link}
              />
            ) : null}
            {feed?.organization.socials.youtube ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.youtube.link}
              />
            ) : null}
          </HStack>
        </Stack>
        {feed?.organization.feed_image ? (
          <Box position={"relative"} zIndex={"100"} mb={"-40px"}>
            <Image
              rounded={"xl"}
              src={feed?.organization.feed_image}
              maxW={"300px"}
            />
            <Box
              position={"absolute"}
              bg="black"
              right="0"
              bottom="0"
              roundedBottomRight={"xl"}
              roundedTopLeft={"xl"}
              px={5}
              py={3}
            >
              <Image src={lightsmall} maxW={"50px"} />
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );

  const renderMobileHeader = () => (
    <Stack w="100%" spacing={0} color={"white"}>
      <VStack w="100%">
        <Box position={"relative"}>
          <Image
            rounded={"xl"}
            src={feed?.organization.feed_image}
            maxW={"200px"}
          />
          <Box
            position={"absolute"}
            bg="black"
            right="0"
            bottom="0"
            roundedBottomRight={"xl"}
            roundedTopLeft={"2xl"}
            px={3}
            py={1}
          >
            <Image src={lightsmall} maxW={"40px"} />
          </Box>
        </Box>
      </VStack>
      <Stack py={3} px={3} spacing={3}>
        <Stack spacing={-2}>
          <Text fontSize={"4xl"} fontWeight={"bold"}>
            {feed?.organization?.name}
          </Text>
          <HStack fontSize={"sm"}>
            <Text>{feed?.organization.display_verified_fans}</Text>
          </HStack>
        </Stack>

        <HStack>
          {feed?.organization.socials.twitter ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.twitter.link}
            />
          ) : null}
          {feed?.organization.socials.instagram ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.instagram.link}
            />
          ) : null}
          {feed?.organization.socials.spotify ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.spotify.link}
            />
          ) : null}
          {feed?.organization.socials.applemusic ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.applemusic.link}
            />
          ) : null}
          {feed?.organization.socials.soundcloud ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.soundcloud.link}
            />
          ) : null}
          {feed?.organization.socials.youtube ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.youtube.link}
            />
          ) : null}
        </HStack>
      </Stack>
    </Stack>
  );

  return (
    <Div100vh>
      <VStack w="100vw" h="100%" justifyContent={"space-between"}>
        <Stack spacing={0} h="100%" w="100%">
          <VStack spacing={{ base: 0, md: 0 }} w="100%">
            {feed && feed.kyd_events.length > 0 ? (
              <SimpleGrid w="100%" minChildWidth={"400px"} spacing={"10px"}>
                {feed.kyd_events.map(renderEvent)}
              </SimpleGrid>
            ) : null}

            <Box w="100%" borderColor="gray.200">
              <Stack spacing={0} w="100%" h="100%">
                <HStack justify={"space-between"} p={3}>
                  <HStack spacing={4}>
                    <Image maxW={"60px"} src={logoFooterDark} />

                    <Text fontSize={"xs"} textDecor={"underline"}>
                      <a
                        href="https://www.iubenda.com/privacy-policy/22517592"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </Text>
                  </HStack>
                  <Stack spacing={0}>
                    <Text textAlign={"right"} color="gray.600" fontSize={"xs"}>
                      © {new Date().getFullYear()} KYD Labs Inc.
                    </Text>
                    <Text textAlign={"right"} color="gray.400" fontSize={"xs"}>
                      v{process.env.REACT_APP_VERSION}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
            </Box>
          </VStack>
        </Stack>
      </VStack>
    </Div100vh>
  );
}

export default KYDEmbed;
