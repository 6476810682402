import {
  Text,
  Stack,
  Button,
  Image,
  HStack,
  VStack,
  Select,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  TableContainer,
  Thead,
} from "@chakra-ui/react";
import { KYDEvent as IKYDEvent, KYDEvent } from "@common/interfaces/KYDEvent";
import {
  KYDCart as IKYDCart,
  KYDCart,
  KYDCartLineItem,
} from "@common/interfaces/KYDCart";
import { CognitoUser } from "amazon-cognito-identity-js";

import { useParams, useNavigate } from "react-router-dom";

import { analytics } from "../utils";
import Countdown, { CountdownApi } from "react-countdown";

function KYDCountdown({
  ms,
  didComplete,
}: {
  ms: number;
  didComplete: () => void;
}) {
  const { event_id } = useParams();
  const renderCountdown = ({
    minutes,
    seconds,
    completed,
    api,
  }: {
    minutes: number;
    seconds: number;
    completed: boolean;
    api: CountdownApi;
  }) => {
    if (completed) {
      didComplete();
    } else {
      return (
        <span>
          {`${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`}
        </span>
      );
    }
  };

  return <Countdown date={ms} renderer={renderCountdown} />;
}

export default KYDCountdown;
