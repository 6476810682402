import { Box, Button, Spinner, Stack, Text, VStack } from "@chakra-ui/react";
import { Dayjs } from "dayjs";
import { formatDate } from "../utils";

const RemindMeOnSale = ({
  show,
  hasReminder,
  onRemindMe,
  onSaleDate,
  isLoading = false,
}: {
  show: boolean;
  isLoading?: boolean;
  onSaleDate: Dayjs;
  hasReminder: boolean;
  onRemindMe: () => void;
}) => {
  if (!show) {
    return null;
  }
  return (
    <Stack mt={2}>
      {hasReminder ? (
        <Stack>
          <Text
            fontWeight={"bold"}
            color={{ base: "white", md: "black" }}
            mb={4}
          >
            You're all set! We'll send you a text as soon as tickets for this
            event go on sale ✨
          </Text>
          <VStack>
            <Text textAlign={"center"} color={{ base: "white", md: "black" }}>
              Tickets on Sale: {formatDate(onSaleDate)}
            </Text>
            <Button
              isLoading={isLoading}
              isDisabled
              borderColor="gray.400"
              borderWidth={1}
              bg="black"
              color="white"
              _hover={{ bg: "gray.800" }}
              maxW={"300px"}
              w="100%"
            >
              ✅ Reminder Set!
            </Button>
          </VStack>
        </Stack>
      ) : (
        <Stack w="100%">
          <Text
            fontWeight={"bold"}
            color={{ base: "white", md: "black" }}
            mb={4}
          >
            🔥 Get notified when this event goes on sale!
          </Text>
          <VStack w="100%">
            <Text textAlign="center" color={{ base: "white", md: "black" }}>
              Tickets on Sale: {formatDate(onSaleDate)}
            </Text>
            <Button
              maxW={"300px"}
              w="100%"
              isLoading={isLoading}
              bg={{ base: "white", md: "black" }}
              color={{ base: "black", md: "white" }}
              _hover={{ bg: { base: "gray.200", md: "gray.800" } }}
              onClick={onRemindMe}
            >
              ⏰ Remind Me!
            </Button>
          </VStack>
        </Stack>
      )}
    </Stack>
  );
};

export default RemindMeOnSale;
