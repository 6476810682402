import React, { useCallback, useEffect, useState } from "react";
import actions from "../actions";
import {
  Box,
  Text,
  Stack,
  Image,
  HStack,
  VStack,
  Button,
  Spinner,
  useBreakpointValue,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { KYDEventBundle, KYDEventSummary } from "@common/interfaces/KYDEvent";
import Div100vh from "react-div-100vh";

import logoFooter from "../lightsmall.png";
import logoFooterDark from "../darksmall.png";

import { analytics, showErrorToast } from "../utils";
import "react-phone-input-2/lib/bootstrap.css";

import lightsmall from "../lightsmall.png";
import { useParams } from "react-router-dom";
import BioEvent from "../Bio/Event";
import { SocialIcon } from "react-social-icons";

function EventBundle() {
  const [eventBundle, setEventBundle] = useState<KYDEventBundle | null>(null);
  const [_loading, setLoading] = useState<any>({});

  const spinnerColor = useBreakpointValue({ base: "white", md: "black" });

  const isDesktop = useBreakpointValue({ base: false, md: true });
  const { bundle_id } = useParams();
  const toast = useToast();
  const updateLoadingState = useCallback((key: "bundle", value: boolean) => {
    setLoading((l: any) => {
      return { ...l, [key]: value };
    });
  }, []);

  useEffect(() => {
    const fetchEventBundle = async (_bundle_id: string) => {
      updateLoadingState("bundle", true);
      try {
        const _ = await actions.USER.fetchEventBundle(_bundle_id);
        if (_) {
          document.title = _.title || _.organization?.name;
        }
        setEventBundle(_);
      } catch (err: any) {
        showErrorToast(toast, "Link not valid");
        //TODO: show error
        analytics.t("error: failed to load upcoming events", {
          message: err && err.message,
        });
      }
      updateLoadingState("bundle", false);
    };

    if (bundle_id) {
      analytics.t("pageview", {
        type: "bundle",
        bundle_id,
      });

      fetchEventBundle(bundle_id);
    }
  }, [bundle_id]);

  const renderDesktopHeader = () => (
    <Box w="100%" px={4}>
      <Box
        w="100%"
        color={"white"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Stack py={3} spacing={3}>
          <Stack spacing={-2}>
            <Text fontSize={"5xl"} fontWeight={"bold"}>
              {eventBundle?.organization?.name}
            </Text>
            <HStack>
              <Text>{eventBundle?.organization?.display_verified_fans}</Text>
            </HStack>
          </Stack>

          <HStack>
            {eventBundle?.organization?.socials?.twitter ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={eventBundle?.organization.socials.twitter.link}
              />
            ) : null}
            {eventBundle?.organization?.socials?.instagram ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={eventBundle.organization.socials.instagram.link}
              />
            ) : null}
            {eventBundle?.organization?.socials?.spotify ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={eventBundle.organization.socials.spotify.link}
              />
            ) : null}
            {eventBundle?.organization?.socials?.applemusic ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={eventBundle.organization.socials.applemusic.link}
              />
            ) : null}
            {eventBundle?.organization?.socials?.soundcloud ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={eventBundle?.organization.socials.soundcloud.link}
              />
            ) : null}
            {eventBundle?.organization?.socials?.youtube ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={eventBundle.organization.socials.youtube.link}
              />
            ) : null}
          </HStack>
        </Stack>
        {eventBundle?.organization.feed_image ? (
          <Box position={"relative"} zIndex={"100"} mb={"-40px"}>
            <Image
              rounded={"xl"}
              src={eventBundle?.organization.feed_image}
              maxW={"300px"}
            />
            {eventBundle ? (
              <Box
                position={"absolute"}
                bg="black"
                right="0"
                bottom="0"
                roundedBottomRight={"xl"}
                roundedTopLeft={"xl"}
                px={5}
                py={3}
              >
                <Image src={lightsmall} maxW={"50px"} />
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Box>
  );

  const renderMobileHeader = () => (
    <Stack w="100%" spacing={0} color={"white"}>
      <Stack py={3} px={3} spacing={3}>
        <Stack spacing={-2}>
          <Text fontSize={"4xl"} fontWeight={"bold"}>
            {eventBundle?.organization?.name}
          </Text>
          <HStack fontSize={"sm"}>
            <Text>{eventBundle?.organization?.display_verified_fans}</Text>
          </HStack>
        </Stack>

        <HStack>
          {eventBundle?.organization?.socials?.twitter ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={eventBundle?.organization.socials.twitter.link}
            />
          ) : null}
          {eventBundle?.organization?.socials?.instagram ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={eventBundle.organization.socials.instagram.link}
            />
          ) : null}
          {eventBundle?.organization?.socials?.spotify ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={eventBundle.organization.socials.spotify.link}
            />
          ) : null}
          {eventBundle?.organization?.socials?.applemusic ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={eventBundle.organization.socials.applemusic.link}
            />
          ) : null}
          {eventBundle?.organization?.socials?.soundcloud ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={eventBundle?.organization.socials.soundcloud.link}
            />
          ) : null}
          {eventBundle?.organization?.socials?.youtube ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={eventBundle.organization.socials.youtube.link}
            />
          ) : null}
        </HStack>
      </Stack>
    </Stack>
  );

  const renderEvent = (kyd_event: KYDEventSummary, index: number) => {
    return <BioEvent kyd_event={kyd_event} borderBottom="0px" />;
  };

  return (
    <Div100vh>
      <VStack
        overflow={"scroll"}
        w="100%"
        h="100%"
        bgGradient="linear-gradient(322.9deg, #8B8B8B -32.49%, #000000 30.1%, #332E38 97.31%)"
        backgroundSize={"cover"}
        justifyContent={"space-between"}
      >
        <Stack spacing={0} w="100%" maxW={["100%", null, "3xl"]}>
          {isDesktop ? (
            <>
              <Box zIndex={1000} w="100%" p={0}>
                <VStack zIndex={1001} w="100%">
                  <HStack
                    px={5}
                    justify={"space-between"}
                    w="100%"
                    h={{ base: "100px", md: "100px" }}
                  >
                    <Image
                      p={2}
                      src={eventBundle?.organization?.logo_url}
                      maxW="100px"
                    />
                  </HStack>
                </VStack>
              </Box>
              {renderDesktopHeader()}
            </>
          ) : null}

          <VStack pb={[0, null, 10]} spacing={{ base: 0, md: 0 }} w="100%">
            <VStack
              display={eventBundle ? "none" : "inherit"}
              roundedTop={{ base: "none", md: "lg" }}
              bg={{ base: "none", md: "white" }}
              py={4}
              w="100%"
            >
              {_loading["bundle"] ? <Spinner color={spinnerColor} /> : null}
            </VStack>

            {eventBundle && eventBundle.events.length > 0 ? (
              <Stack
                w="100%"
                bg={"white"}
                roundedTop={{ base: "none", md: "lg" }}
                roundedBottom={"none"}
                borderColor="gray.200"
                shadow={"lg"}
                px={[3, null, 5]}
                pt={[3, null, "50px"]}
                pb={4}
              >
                {eventBundle.title ? (
                  <Text fontWeight={"medium"} fontSize={"2xl"}>
                    {eventBundle.title}
                  </Text>
                ) : null}
                <VStack spacing={4}>
                  {eventBundle.events.map(renderEvent)}
                </VStack>
              </Stack>
            ) : null}

            {!isDesktop ? renderMobileHeader() : null}

            <Box
              w="100%"
              rounded={{ base: "none", md: "lg" }}
              borderColor="gray.200"
              shadow={"lg"}
            >
              <Stack spacing={0}>
                <HStack
                  display={{ base: "none", md: "flex" }}
                  borderTop={"1px"}
                  borderTopColor="gray.200"
                  justify={"space-between"}
                  p={3}
                  roundedBottom={"lg"}
                  bg="gray.50"
                >
                  <HStack spacing={4}>
                    <Image maxW={"60px"} src={logoFooterDark} />
                    <Text fontSize={"xs"} textDecor={"underline"}>
                      <a
                        href="https://www.iubenda.com/terms-and-conditions/22517592"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Terms Of Service
                      </a>
                    </Text>
                    <Text fontSize={"xs"} textDecor={"underline"}>
                      <a
                        href="https://www.iubenda.com/privacy-policy/22517592"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </Text>
                  </HStack>
                  <Stack spacing={0}>
                    <Text textAlign={"right"} color="gray.600" fontSize={"xs"}>
                      © {new Date().getFullYear()} KYD Labs Inc.
                    </Text>
                    <Text textAlign={"right"} color="gray.400" fontSize={"xs"}>
                      v{process.env.REACT_APP_VERSION}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
            </Box>
          </VStack>
        </Stack>

        <VStack
          display={{ base: "flex", md: "none" }}
          py={4}
          bg="transparent"
          w="100%"
          color="white"
        >
          <VStack pb={2} spacing={1}>
            <Image maxW={"75px"} src={logoFooter} />
            <HStack>
              <Text fontSize={"xs"} textDecor={"underline"}>
                <a
                  href="https://www.iubenda.com/terms-and-conditions/22517592"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Terms Of Service
                </a>
              </Text>
              <Text fontSize={"xs"} textDecor={"underline"}>
                <a
                  href="https://www.iubenda.com/privacy-policy/22517592"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </Text>
            </HStack>
            <HStack>
              <Text color="gray.200" fontSize={"xs"}>
                © {new Date().getFullYear()} KYD Labs Inc.
              </Text>
              <Text color="gray.400" fontSize={"xs"}>
                v{process.env.REACT_APP_VERSION}
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </Div100vh>
  );
}

export default EventBundle;
