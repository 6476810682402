import { HStack, Stack, Text, useClipboard, useToast } from "@chakra-ui/react";
import { analytics, showSuccessToast } from "../utils";

const MaxLimitReached = ({ share_link }) => {
  const { onCopy } = useClipboard(share_link || "");
  const toast = useToast();
  return (
    <Stack align={"right"} spacing={1}>
      <Stack spacing={0}>
        <Text
          lineHeight={"shorter"}
          fontWeight={"medium"}
          fontSize={"sm"}
          color={"gray.500"}
        >
          Max limit reached.
        </Text>
        <Text lineHeight={"shorter"} fontSize={"sm"} color={"gray.500"}>
          Want to share with a friend?
        </Text>
      </Stack>

      <HStack>
        <Text
          w="125px"
          fontSize={"xs"}
          fontWeight={"bold"}
          borderBottom={"1px"}
          borderColor={"gray.300"}
        >
          {share_link?.split("://").pop()}
        </Text>
        <Text
          fontSize={"xs"}
          color="blue.500"
          fontWeight={"bold"}
          cursor={"pointer"}
          onClick={() => {
            analytics.t("event: copied share link", {
              location: "tickettypes",
            });
            showSuccessToast(toast, "✅ Link copied!");
            onCopy();
          }}
        >
          COPY LINK
        </Text>
      </HStack>
    </Stack>
  );
};

export default MaxLimitReached;
