import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Text,
  Stack,
  Button,
  Image,
  HStack,
  VStack,
  Box,
  Table,
  Th,
  Tr,
  Td,
  Tbody,
  TableContainer,
  Thead,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { KYDEvent as IKYDEvent, KYDEvent } from "@common/interfaces/KYDEvent";
import {
  KYDCartDisplayLineItemV2,
  KYDCartLineItemV2,
  KYDCartV2,
} from "@common/interfaces/KYDCart";
import { CognitoUser } from "amazon-cognito-identity-js";

import { useParams, useNavigate } from "react-router-dom";

import { ModalType, analytics } from "../utils";

import { FaRegTimesCircle, FaTimes } from "react-icons/fa";
import { CheckoutParams } from ".";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";

import Billing from "./Billing";
import { useForm, FormProvider } from "react-hook-form";
import Attendee from "./Attendee";
import {
  AddIcon,
  CloseIcon,
  MinusIcon,
  SmallCloseIcon,
} from "@chakra-ui/icons";
import { KYDTicketType } from "@common/interfaces/KYDTicket";
import { Wallet } from "@common/interfaces/KYDUser";

type FormInputs = {
  first_name: string;
  last_name: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  country: string;
  store_for_future_use: boolean;
  stored_cvv: string;
  payment_method_id: string | null;
};

function KYDCartDetail({
  stripeObject,
  kydEvent,
  currentUser,
  cart,
  onCheckout,
  onSignIn,
  onClearCart,
  checkoutLoading,
  onApplyPromoCode,
  onRemovePromocode,
  modalType,
  promocodeErrors,
  cleanPromocodeErrors,
  stripeRef,
  elementsRef,
  radarRef,
  onUpdateTicketTypeQuantity,
  wallet,
}: {
  stripeObject: Stripe;
  kydEvent: IKYDEvent;
  currentUser: CognitoUser | null;
  cart: KYDCartV2 | null;
  onCheckout: (params: CheckoutParams) => void;
  onApplyPromoCode: (promoCode: string) => Promise<void>;
  onRemovePromocode: (promoCode: string) => Promise<void>;
  onSignIn: () => void;
  onClearCart: () => void;
  checkoutLoading: boolean;
  modalType: ModalType;
  cleanPromocodeErrors: () => void;
  promocodeErrors: Record<string, any>;
  stripeRef: React.MutableRefObject<Stripe>;
  elementsRef: React.MutableRefObject<StripeElements>;
  radarRef: React.MutableRefObject<any>;
  onUpdateTicketTypeQuantity: (
    ticket_type: {
      id: KYDTicketType["id"];
      limit: KYDTicketType["limit"];
      remaining: KYDTicketType["remaining"];
    },
    action: "add" | "sub" | "set" | "delete"
  ) => void;
  wallet: Wallet;
}) {
  const navigate = useNavigate();
  const { event_id } = useParams();
  const [currentPromoCode, setCurrentPromoCode] = useState<string>("");

  const promocodeInputRef = useRef<HTMLInputElement | null>();
  const methods = useForm<FormInputs>();

  useEffect(() => {
    if (modalType === ModalType.EXPIRED_CART) {
      cleanPromocodeErrors();
    }
  }, [modalType]);

  useEffect(() => {
    if (cart) {
      if (cart.first_name) methods.setValue("first_name", cart.first_name);
      if (cart.last_name) methods.setValue("last_name", cart.last_name);
      if (cart.email) methods.setValue("email", cart.email);

      if (
        cart.stored_payment_methods &&
        cart.stored_payment_methods.length > 0
      ) {
        console.log(
          "Set payment method id: ",
          cart.stored_payment_methods[0].id
        );
        methods.setValue(
          "payment_method_id",
          cart.stored_payment_methods[0].id
        );
      } else {
        methods.setValue("payment_method_id", null);
      }
    }
  }, [cart]);

  const hasPromocodeErrors = promocodeErrors["promocode"];
  const [paymentFormComplete, setPaymentForComplete] = useState<boolean>(false);

  const hasTicketTypeReachedLimit = (ticketTypeId: string) => {
    const tt = kydEvent.ticket_types.find((tt) => tt.id === ticketTypeId);

    let tickets = 0;
    if (wallet) {
      tickets += wallet.tickets.filter(
        (ticket) => ticket.ticket_type_id === tt.id
      ).length;
    }

    if (cart && cart.version === "v2") {
      tickets += (cart as KYDCartV2).line_items.filter((li) => {
        return (li as KYDCartLineItemV2).entity_type_id === tt.id;
      }).length;
    }

    return tickets + tt.increment > Math.min(tt.limit, tt.remaining);
  };

  const onUpdateTicketTypeQuantityFromLineItem =
    (
      displayLineItem: KYDCartDisplayLineItemV2,
      action: "add" | "sub" | "delete"
    ) =>
    () => {
      const fullLineItem = cart.line_items.find(
        (li) => li.entity_type_id === displayLineItem.entity_type_id
      );

      const ticketType = kydEvent.ticket_types.find(
        (tt) => tt.id === fullLineItem.entity_type_id
      );

      onUpdateTicketTypeQuantity(ticketType, action);
    };

  const onLocalApplyPromoCode = useCallback(
    async (code: string) => {
      return onApplyPromoCode(code)
        .then(() => {
          setCurrentPromoCode("");
        })
        .catch((e) => {
          promocodeInputRef.current?.focus();
          console.log("Error applying promocode", e);
        });
    },
    [onApplyPromoCode]
  );

  const renderSignIn = () => (
    <VStack my={4}>
      <Text>Please sign in to view your cart 🛒</Text>
      <Button
        maxW={"300px"}
        onClick={() => {
          analytics.t("cart: sign in");
          onSignIn();
        }}
        variant="kydDark"
      >
        Sign In
      </Button>
    </VStack>
  );

  const renderCartEmpty = () => (
    <VStack my={4}>
      <Text maxW={"400px"}>
        No tickets in your cart! Head back to the event page to reserve your
        tickets.
      </Text>
      <Button
        maxW={"200px"}
        variant="kydDark"
        onClick={() => {
          analytics.t("cart: go back");
          const searchParams = new URLSearchParams(window.location.search);
          navigate(`/e/${event_id}?${searchParams.toString()}`);
        }}
      >
        Get Tickets
      </Button>
    </VStack>
  );

  const renderCart_v2 = (_cart: KYDCartV2, kydEvent: KYDEvent) => {
    return (
      <VStack roundedTop={"lg"} w="100%" spacing={4}>
        <TableContainer w="100%">
          <Table whiteSpace={"normal"} size={"lg"} variant="simple">
            <Thead>
              <Tr>
                <Th textAlign={{ base: "center", md: "left" }}>Ticket Type</Th>
                <Th
                  display={{ base: "none", md: "table-cell" }}
                  textAlign={"right"}
                >
                  Quantity
                </Th>
                {kydEvent.display_mode === "normal" || !_cart.bypass_billing ? (
                  <Th
                    display={{ base: "none", md: "table-cell" }}
                    textAlign={"right"}
                  >
                    Price
                  </Th>
                ) : null}

                {kydEvent.display_mode === "normal" || !_cart.bypass_billing ? (
                  <Th
                    display={{ base: "none", md: "table-cell" }}
                    textAlign={"right"}
                  >
                    Subtotal
                  </Th>
                ) : null}
                <Th display={{ base: "none", md: "table-cell" }}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {_cart?.display_line_items.map((li) => (
                <Tr key={li.entity_type_id}>
                  <Td display={{ base: "none", md: "table-cell" }}>
                    <Stack spacing={0}>
                      <Text fontSize={"md"} fontWeight={"bold"}>
                        {li.name}
                      </Text>
                    </Stack>
                  </Td>
                  <Td
                    textAlign={"right"}
                    display={{ base: "none", md: "table-cell" }}
                  >
                    <VStack>
                      <>
                        <HStack w="100%" justify={"flex-end"}>
                          <IconButton
                            onClick={onUpdateTicketTypeQuantityFromLineItem(
                              li,
                              "sub"
                            )}
                            isDisabled={li.display_quantity === "1"}
                            aria-label="Subtract"
                            icon={<MinusIcon />}
                          />
                          <Text
                            fontWeight={"bold"}
                            textAlign={"center"}
                            minW={"20px"}
                          >
                            {li.display_quantity}
                          </Text>
                          <IconButton
                            onClick={onUpdateTicketTypeQuantityFromLineItem(
                              li,
                              "add"
                            )}
                            isDisabled={hasTicketTypeReachedLimit(
                              li.entity_type_id
                            )}
                            aria-label="Add"
                            icon={<AddIcon />}
                          />
                        </HStack>
                      </>
                    </VStack>
                  </Td>
                  {kydEvent.display_mode === "normal" ||
                  !_cart.bypass_billing ? (
                    <Td
                      textAlign={"right"}
                      display={{ base: "none", md: "table-cell" }}
                    >
                      <HStack spacing={1} justify={"right"}>
                        <Text>{li.display_unit_amount}</Text>
                        {kydEvent.display_options?.show_currency ? (
                          <Text fontSize={"xs"}>{kydEvent.currency}</Text>
                        ) : null}
                      </HStack>
                    </Td>
                  ) : null}

                  {kydEvent.display_mode === "normal" ||
                  !_cart.bypass_billing ? (
                    <Td
                      textAlign={"right"}
                      display={{ base: "none", md: "table-cell" }}
                    >
                      {li.display_discount_amount ? (
                        <Stack spacing={0}>
                          <Text>{li.display_subtotal_amount}</Text>
                          <Text color="gray.500">
                            {li.display_discount_amount}
                          </Text>
                        </Stack>
                      ) : (
                        <HStack spacing={1} justify={"right"}>
                          <Text>{li.display_subtotal_amount}</Text>
                          {kydEvent.display_options?.show_currency ? (
                            <Text fontSize={"xs"}>{kydEvent.currency}</Text>
                          ) : null}
                        </HStack>
                      )}
                    </Td>
                  ) : null}
                  <Td display={{ base: "table-cell", md: "none" }} px={0}>
                    <VStack pos={"relative"}>
                      {/*<Image maxW={"100px"} src={t.image} />*/}
                      <IconButton
                        onClick={onUpdateTicketTypeQuantityFromLineItem(
                          li,
                          "delete"
                        )}
                        position={"absolute"}
                        variant={"outline"}
                        right={0}
                        top={0}
                        aria-label="Remove"
                        icon={<SmallCloseIcon w={4} h={4} />}
                      />
                      <Text textAlign={"center"} fontWeight={"bold"}>
                        {li.name}
                      </Text>
                      <Text fontSize={"xs"}>
                        {
                          kydEvent.ticket_types.find(
                            (tt) => tt.id === li.entity_type_id
                          )?.description
                        }
                      </Text>
                      <VStack>
                        <>
                          <HStack w="100%" justify={"flex-end"}>
                            <IconButton
                              onClick={onUpdateTicketTypeQuantityFromLineItem(
                                li,
                                "sub"
                              )}
                              isDisabled={li.display_quantity === "1"}
                              aria-label="Subtract"
                              icon={<MinusIcon />}
                            />
                            <Text
                              fontWeight={"bold"}
                              textAlign={"center"}
                              minW={"20px"}
                            >
                              {li.display_quantity}
                            </Text>
                            <IconButton
                              onClick={onUpdateTicketTypeQuantityFromLineItem(
                                li,
                                "add"
                              )}
                              isDisabled={hasTicketTypeReachedLimit(
                                li.entity_type_id
                              )}
                              aria-label="Add"
                              icon={<AddIcon />}
                            />
                          </HStack>
                        </>
                      </VStack>
                      {/* <Text>Quantity: {li.display_quantity}</Text> */}
                      <HStack spacing={1}>
                        {kydEvent.display_mode === "normal" ||
                        !_cart.bypass_billing ? (
                          <Text>Price: {li.display_unit_amount}</Text>
                        ) : null}
                        {kydEvent.display_options?.show_currency ? (
                          <Text fontSize={"xs"}>{kydEvent.currency}</Text>
                        ) : null}
                      </HStack>

                      <HStack spacing={1}>
                        {kydEvent.display_mode === "normal" ||
                        !_cart.bypass_billing ? (
                          <Text>Total: {li.display_subtotal_amount}</Text>
                        ) : null}
                        {kydEvent.display_options?.show_currency ? (
                          <Text fontSize={"xs"}>{kydEvent.currency}</Text>
                        ) : null}
                      </HStack>
                    </VStack>
                  </Td>
                  <Td
                    display={{ base: "none", md: "flex" }}
                    justifyContent={"flex-end"}
                    pr={0}
                    height="100%"
                  >
                    <IconButton
                      onClick={onUpdateTicketTypeQuantityFromLineItem(
                        li,
                        "delete"
                      )}
                      variant={"outline"}
                      aria-label="Remove"
                      icon={<SmallCloseIcon w={4} h={4} />}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Stack w="100%" align={"flex-end"}>
          <HStack cursor={"pointer"} onClick={onClearCart}>
            <Icon as={FaRegTimesCircle} color="gray.500" />
            <Text color="gray.500">Clear Cart</Text>
          </HStack>
        </Stack>

        {kydEvent.display_mode === "normal" || !cart?.bypass_billing ? (
          <Stack w="100%" align={"flex-end"}>
            <Stack maxW={"240px"} spacing={1}>
              <InputGroup size="md">
                <VStack>
                  <Input
                    ref={promocodeInputRef}
                    onChange={(e) => {
                      cleanPromocodeErrors();
                      setCurrentPromoCode(e.target.value);
                    }}
                    value={currentPromoCode}
                    pr="4.5rem"
                    type={"text"}
                    isInvalid={hasPromocodeErrors}
                    borderColor={hasPromocodeErrors ? "kydRed.200" : "gray.200"}
                    placeholder="Promo Code"
                  />
                  {hasPromocodeErrors ? (
                    <Text color="red.500" w="100%" fontSize="xs">
                      ❌ {promocodeErrors["promocode"].message}
                    </Text>
                  ) : null}
                </VStack>
                <InputRightElement width="4.5rem">
                  <Button
                    onClick={() => onLocalApplyPromoCode(currentPromoCode)}
                    isDisabled={
                      !currentPromoCode ||
                      currentPromoCode.length === 0 ||
                      checkoutLoading
                    }
                    isLoading={checkoutLoading}
                    h="1.75rem"
                    size="sm"
                  >
                    Apply
                  </Button>
                </InputRightElement>
              </InputGroup>
              {cart &&
                cart.promo_codes.length > 0 &&
                cart.promo_codes.map((pc) => (
                  <HStack width="100%" justify={"flex-end"}>
                    <Text color="green.500" fontWeight={"bold"}>
                      {pc.description}
                    </Text>
                    <IconButton
                      onClick={() => onRemovePromocode(pc.code)}
                      isDisabled={checkoutLoading}
                      isLoading={checkoutLoading}
                      h="1.75rem"
                      size="sm"
                      icon={<FaTimes />}
                      variant={"outline"}
                      aria-label="Clear coupon code"
                    />
                  </HStack>
                ))}
            </Stack>
            {kydEvent.display_options?.price_mode === "exclusive" ||
            _cart?.display_tax_total ? (
              <HStack textAlign={"right"} justify={"right"}>
                <Text>Subtotal:</Text>
                <Text minW={"100px"}>{cart?.display_subtotal}</Text>
              </HStack>
            ) : null}
            {_cart.display_discount_total && !_cart.bypass_billing ? (
              <HStack textAlign={"right"} justify={"right"}>
                <Text>Discounts:</Text>
                <Text minW={"100px"}>{_cart.display_discount_total}</Text>
              </HStack>
            ) : null}
            {kydEvent.display_options?.price_mode !== "inclusive" ? (
              <HStack textAlign={"right"} justify={"right"}>
                <Text>Fees:</Text>
                <Text minW={"100px"}>{cart?.display_fees_total}</Text>
              </HStack>
            ) : null}

            {_cart?.display_tax_total ? (
              <HStack textAlign={"right"} justify={"right"}>
                <Text>Sales Tax {`(${cart?.display_tax_rate})`}:</Text>
                <Text minW={"100px"}>{cart?.display_tax_total}</Text>
                {kydEvent.display_options?.show_currency ? (
                  <Text fontSize={"xs"}>{kydEvent.currency}</Text>
                ) : null}
              </HStack>
            ) : null}
            <HStack
              borderTop={"1px"}
              borderTopColor={"gray.200"}
              textAlign={"right"}
              justify={"right"}
            >
              <Text>Total:</Text>
              <Text minW={"100px"}>{cart?.display_total}</Text>
              {kydEvent.display_options?.show_currency ? (
                <Text fontSize={"xs"}>{kydEvent.currency}</Text>
              ) : null}
            </HStack>
          </Stack>
        ) : null}
      </VStack>
    );
  };

  const renderCheckoutButton = () => {
    if (cart && cart.bypass_billing) {
      return (
        <Button
          w="100%"
          maxW={"lg"}
          isLoading={checkoutLoading}
          bg={"green.500"}
          _hover={{ bg: "gray.800" }}
          onClick={methods.handleSubmit((data) =>
            onCheckout({ formData: data, type: "free" })
          )}
          color="white"
        >
          {kydEvent.display_mode === "normal"
            ? "Complete Checkout"
            : "Complete Registration"}
        </Button>
      );
    } else {
      return (
        <VStack w="100%">
          <Button
            w="100%"
            maxW={"lg"}
            isDisabled={!stripeRef.current || !paymentFormComplete}
            isLoading={checkoutLoading}
            bg={"green.500"}
            _hover={{ bg: "gray.800" }}
            onClick={methods.handleSubmit((data) =>
              onCheckout({ formData: data, type: "card" })
            )}
            color="white"
          >
            Pay {cart.display_total}
            {kydEvent.display_options?.show_currency
              ? ` ${kydEvent.currency}`
              : ""}
          </Button>
          <Text fontSize={"sm"} color="gray.500">
            Your card will be charged in {kydEvent.currency}
          </Text>
        </VStack>
      );
    }
  };

  return (
    <VStack spacing={4} p={{ base: "2", md: "0" }}>
      <Box
        p={5}
        bg="white"
        roundedBottom={{
          base: "lg",
          md: cart && currentUser && cart.line_items.length > 0 ? "lg" : "none",
        }}
        roundedTop={"lg"}
        w="100%"
      >
        <Text w="100%" textAlign={"left"} fontWeight={"bold"} fontSize="xl">
          Cart
        </Text>
        {!currentUser && renderSignIn()}
        {((currentUser && !cart) ||
          (cart && currentUser && cart.line_items.length === 0)) &&
          renderCartEmpty()}
        {cart && currentUser && cart.line_items.length > 0
          ? renderCart_v2(cart as KYDCartV2, kydEvent)
          : null}
      </Box>
      {cart && currentUser && cart.line_items.length > 0 && kydEvent && (
        <VStack
          p={5}
          bg="white"
          roundedTop={"lg"}
          roundedBottom={{ base: "lg", md: "none" }}
          w="100%"
          spacing={4}
        >
          <FormProvider {...methods}>
            {cart && cart.bypass_billing ? (
              <Attendee kydEvent={kydEvent} cart={cart} />
            ) : (
              <Elements
                stripe={stripeObject}
                options={{
                  mode: "payment",
                  amount: cart?.total,
                  currency: cart?.currency.toLowerCase(),
                  loader: "never",
                  paymentMethodCreation: "manual",
                }}
              >
                <Billing
                  kydEvent={kydEvent}
                  cart={cart}
                  onExpressCheckout={methods.handleSubmit((data) =>
                    onCheckout({ formData: data, type: "express" })
                  )}
                  paymentFormComplete={paymentFormComplete}
                  setPaymentFormComplete={setPaymentForComplete}
                  stripeRef={stripeRef}
                  elementsRef={elementsRef}
                  radarRef={radarRef}
                />
              </Elements>
            )}
          </FormProvider>
          {renderCheckoutButton()}
        </VStack>
      )}
    </VStack>
  );
}

export default KYDCartDetail;
