import { Text, Button, VStack } from "@chakra-ui/react";
import { KYDEvent as IKYDEvent } from "@common/interfaces/KYDEvent";

import { useParams, useNavigate } from "react-router-dom";

import { analytics } from "../utils";

function KYDSuccess({ kydEvent }: { kydEvent: IKYDEvent }) {
  const navigate = useNavigate();
  const { event_id } = useParams();

  return (
    <VStack p={5}>
      <Text fontWeight={"bold"} fontSize="lg">
        ✅ Tickets Confirmed
      </Text>
      <Text>
        Your tickets are now being processed and will be available in your
        wallet within 30 seconds.
      </Text>

      <Button
        onClick={() => {
          analytics.t("success: my tickets");
          navigate(`/e/${event_id}/wallet${window.location.search}`);
        }}
        variant="kydDark"
      >
        My Tickets
      </Button>
    </VStack>
  );
}

export default KYDSuccess;
