import { KYDEvent } from "@common/interfaces/KYDEvent";
import { KYDTicketType } from "@common/interfaces/KYDTicket";
import { Wallet } from "@common/interfaces/KYDUser";
import { useNavigate } from "react-router-dom";
import MaxLimitReached from "./MaxLimitReached";
import {
  isWaitlistTicketType,
  ticketTypeHasWaitlistBuyOrder,
  ticketTypeHasWaitlistSellOrder,
} from "./WaitList/utils";
import JoinWaitlistButton from "./WaitList/JoinWaitlistButton";
import { Td, Text, VStack, Box, Stack } from "@chakra-ui/react";
import TicketTypeQuantityWidget from "./TicketTypeQuantityWidget";

const TicketTypeQuantityTd = ({
  kydEvent,
  wallet,
  ticketType: tt,
  updateQuantity,
  quantities,
  onJoinWaitlist,
  isWalletLoading = false,
}: {
  kydEvent: KYDEvent | null;
  wallet: Wallet | null;
  updateQuantity: (
    ticket_type: KYDTicketType,
    action: string,
    value?: number
  ) => void;
  quantities: any;
  ticketType: KYDTicketType & { maxLimitReached: boolean };
  onJoinWaitlist: (tt: KYDTicketType) => void;
  isWalletLoading: boolean;
}) => {
  const navigate = useNavigate();
  const TdsToRender = [];

  if (tt.maxLimitReached && wallet?.share_link && !isWaitlistTicketType(tt)) {
    TdsToRender.push(<MaxLimitReached share_link={wallet?.share_link} />);
  } else if (isWaitlistTicketType(tt) && !tt.next_tier_id) {
    const hasBuyOrder = ticketTypeHasWaitlistBuyOrder(tt, wallet);
    const hasSellOrder = ticketTypeHasWaitlistSellOrder(tt, wallet);
    TdsToRender.push(
      <JoinWaitlistButton
        isLoading={isWalletLoading}
        hasBuyOrder={hasBuyOrder}
        hasSellOrder={hasSellOrder}
        show
        onClick={() => {
          if (hasBuyOrder || hasSellOrder) {
            navigate(`/e/${kydEvent?.id}/wallet`);

            return;
          }

          onJoinWaitlist(tt);
        }}
      />
    );
  } else if (tt.sold_out) {
    TdsToRender.push(
      <Text color="gray.400" textAlign={"right"} w="100%" fontWeight={"bold"}>
        Sold Out
      </Text>
    );
  } else if (kydEvent.status === "PREVIEW") {
    TdsToRender.push(<Text textColor={"gray.500"}>PREVIEW</Text>);
  } else {
    TdsToRender.push(
      <Stack justify={"flex-end"} w="100%">
        <TicketTypeQuantityWidget
          quantities={quantities}
          tt={tt}
          updateQuantity={updateQuantity}
        />
      </Stack>
    );
  }

  return (
    <Box px={2} display={{ base: "none", md: "flex" }}>
      {TdsToRender}
    </Box>
  );
};

export default TicketTypeQuantityTd;
