import {
  Box,
  Button,
  HStack,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useCollapse } from "@collapsed/react";
import { KYDEvent } from "@common/interfaces/KYDEvent";
import { KYDTicketType } from "@common/interfaces/KYDTicket";
import { Wallet } from "@common/interfaces/KYDUser";
import { useNavigate } from "react-router-dom";
import {
  isWaitlistTicketType,
  ticketTypeHasWaitlistBuyOrder,
  ticketTypeHasWaitlistSellOrder,
} from "./WaitList/utils";
import JoinWaitlistButton from "./WaitList/JoinWaitlistButton";
import { Select } from "chakra-react-select";
import { analytics, showSuccessToast } from "../utils";
import { useMemo } from "react";
import { InlineText } from "./WaitList/WaitlistTopCard";

interface KYDTicketTypeCellProps {
  ticketType: KYDTicketType;
  kydEvent: KYDEvent;
  maxLimitReached: boolean;
  shareLink?: string;
  wallet: Wallet;
  onCopy: () => void;
  onJoinWaitlist: (ticketType: KYDTicketType) => void;
  updateQuantity: (
    ticket_type: KYDTicketType,
    action: string,
    value?: number
  ) => void;
  quantities: any;
}

export default function TicketMobileTypeCell(props: KYDTicketTypeCellProps) {
  const { getCollapseProps, setExpanded, isExpanded } = useCollapse({
    defaultExpanded: false,
    collapsedHeight: 20,
  });
  const hasPaidTicketTypes =
    props.kydEvent.ticket_types.filter((tt) => !tt.free).length > 0;
  const toast = useToast();
  const navigate = useNavigate();

  const hasBuyOrder = (tt: KYDTicketType) =>
    ticketTypeHasWaitlistBuyOrder(tt, props.wallet);
  const hasSellOrder = (tt: KYDTicketType) =>
    ticketTypeHasWaitlistSellOrder(tt, props.wallet);

  const selectOptions = useMemo(() => {
    const quantity =
      props.ticketType.limit > props.ticketType.remaining
        ? props.ticketType.remaining
        : props.ticketType.limit;

    return Math.floor((quantity || 1) / props.ticketType.increment || 0);
  }, [props.ticketType]);

  return (
    <Stack
      px={2}
      py={3}
      color={"white"}
      w="100%"
      borderWidth={"1px"}
      borderColor={"white"}
      rounded={"lg"}
      bg="black"
      align={"center"}
    >
      <HStack w="100%" spacing={0} justify={"space-between"}>
        <Stack
          onClick={() => setExpanded(!isExpanded)}
          w={"60%"}
          whiteSpace={"pre-wrap"}
          spacing={-1}
        >
          <Text fontSize={"md"} fontWeight={"bold"}>
            {props.ticketType.name}
          </Text>
          {!props.maxLimitReached && props.ticketType.description ? (
            <Text
              {...getCollapseProps()}
              lineHeight={5}
              fontSize="xs"
              minH={"20px"}
            >
              {props.ticketType.description}
            </Text>
          ) : null}
          {props.ticketType.description &&
            props.ticketType.description.length > 50 &&
            !props.maxLimitReached && (
              <Text fontSize={"xs"} fontWeight={"bold"}>
                see {isExpanded ? "less" : "more"}
              </Text>
            )}
        </Stack>

        <Stack spacing={-1} w={"20%"}>
          {(hasPaidTicketTypes || props.kydEvent.display_mode === "normal") &&
          !props.ticketType.sold_out ? (
            <Text fontSize={"md"} fontWeight={"bold"}>
              {props.ticketType.free ? "FREE" : props.ticketType.display_price}
            </Text>
          ) : null}
          {!props.ticketType.free &&
          props.kydEvent.display_options?.price_mode === "exclusive" ? (
            <Text fontSize={"xs"} color="gray.400">
              {props.ticketType.display_fees_total_price} fee
            </Text>
          ) : null}
          {!props.ticketType.free &&
          props.kydEvent.display_options?.show_currency &&
          !props.ticketType.sold_out ? (
            <Text fontSize={"xs"}>{props.kydEvent.currency}</Text>
          ) : null}
          {props.kydEvent.display_mode === "normal" &&
          props.kydEvent.display_options?.price_mode === "inclusive" &&
          !props.ticketType.sold_out ? (
            <Stack spacing={-3}>
              <Text color="gray.400" fontSize={"10px"}>
                All-In price.
              </Text>
              <Text color="gray.400" fontSize={"10px"}>
                No surprises.
              </Text>
            </Stack>
          ) : null}
        </Stack>
        {isWaitlistTicketType(props.ticketType) &&
        !props.ticketType.next_tier_id ? (
          <Box pl={2}>
            <JoinWaitlistButton
              show
              borderColor="white"
              borderWidth={1}
              px={1}
              onClick={() => {
                if (
                  hasBuyOrder(props.ticketType) ||
                  hasSellOrder(props.ticketType)
                ) {
                  navigate(`/e/${props.kydEvent?.id}/wallet`);

                  return;
                }

                props.onJoinWaitlist(props.ticketType);
              }}
              hasBuyOrder={ticketTypeHasWaitlistBuyOrder(
                props.ticketType,
                props.wallet
              )}
              hasSellOrder={ticketTypeHasWaitlistSellOrder(
                props.ticketType,
                props.wallet
              )}
              isLoading={false}
            />
          </Box>
        ) : props.ticketType.sold_out ? (
          <Text color="gray.400" fontWeight={"bold"}>
            Sold Out
          </Text>
        ) : props.kydEvent.status !== "PREVIEW" ? (
          <Stack
            display={props.maxLimitReached ? "none" : "inherit"}
            spacing={-2.5}
            color={"white"}
            minW={"61px"}
          >
            <Select
              isSearchable={false}
              onChange={(singleValue) =>
                props.updateQuantity(
                  props.ticketType,
                  "set",
                  singleValue!.value
                )
              }
              menuPlacement="auto"
              placeholder={null}
              defaultValue={{
                value: props.quantities[props.ticketType.id] || 0,
                label: props.quantities[props.ticketType.id] || "0",
              }}
              menuPortalTarget={document.body}
              chakraStyles={{
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  background: "none",
                  w: "20px",
                }),
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  p: "0px",
                  w: "15px",
                  justifyContent: "center",
                }),
                valueContainer: (provided, state) => ({
                  ...provided,

                  justifyContent: "center",
                }),
                singleValue: (provided, state) => ({
                  ...provided,
                  maxW: "51px",
                  fontSize: "3xl",
                  fontWeight: "bold",
                  textAlign: "center",
                  overflow: "visible",
                }),
                container: (provided, state) => ({
                  ...provided,
                  maxW: "60px",
                  justifyContent: "flex-end",
                }),
                control: (provided, state) => ({
                  ...provided,
                  border: "none",
                  caretColor: "transparent",
                }),
                indicatorSeparator: (provided, state) => ({
                  ...provided,
                  display: "none",
                }),
              }}
              options={new Array(selectOptions + 1).fill(0).map((i, index) => {
                return {
                  value: index * props.ticketType.increment,
                  label: (index * props.ticketType.increment).toString(),
                };
              })}
            ></Select>
            <Text pl={"10px"} fontSize={"xs"} color="gray.400">
              Qty
            </Text>
          </Stack>
        ) : null}
      </HStack>
      {props.maxLimitReached && props.shareLink ? (
        <Stack w="100%" align={"right"} spacing={1}>
          <Stack w="100%" spacing={0}>
            <Text
              lineHeight={"shorter"}
              fontWeight={"medium"}
              fontSize={"sm"}
              color={"gray.300"}
            >
              Max limit reached.
            </Text>
            <Text lineHeight={"shorter"} fontSize={"sm"} color={"gray.300"}>
              Want to share with a friend?
            </Text>
          </Stack>

          <HStack w="100%">
            <Text
              fontSize={"sm"}
              fontWeight={"bold"}
              borderColor={"gray.300"}
              onClick={async () => {
                analytics.t("event: copied share link", {
                  location: "tickettypes",
                });
                if (window && window.navigator && window.navigator.share) {
                  try {
                    await window.navigator.share({
                      title: props.kydEvent.name,
                      url: props.shareLink,
                    });
                    console.log("Data was shared successfully");
                  } catch (err) {
                    //@ts-ignore
                    console.error("Share failed:", err.message);
                  }
                } else {
                  showSuccessToast(toast, "✅ Link copied!");
                  props.onCopy();
                }
              }}
            >
              {props.shareLink?.split("://").pop()}
            </Text>
            <Text
              borderWidth={"1px"}
              borderColor="white"
              fontSize={"sm"}
              px={2}
              rounded={"md"}
              color="white"
              fontWeight={"bold"}
              cursor={"pointer"}
              onClick={async () => {
                analytics.t("event: copied share link", {
                  location: "tickettypes",
                });
                if (window && window.navigator && window.navigator.share) {
                  try {
                    await window.navigator.share({
                      title: props.kydEvent.name,
                      url: props.shareLink,
                    });
                  } catch (err) {
                    //@ts-ignore
                    console.error("Share failed:", err.message);
                  }
                } else {
                  showSuccessToast(toast, "✅ Link copied!");
                  props.onCopy();
                }
              }}
            >
              Share
            </Text>
          </HStack>
        </Stack>
      ) : null}
    </Stack>
  );
}
