import { Box, Button, ButtonProps, HStack, Spinner } from "@chakra-ui/react";

const JoinWaitlistButton = ({
  show,
  onClick,
  hasBuyOrder = false,
  hasSellOrder = false,
  isLoading = false,
  ...props
}: {
  show: boolean;
  isLoading: boolean;
  hasBuyOrder: boolean;
  hasSellOrder: boolean;
  onClick: () => void;
} & ButtonProps) => {
  if (!show) return null;

  if (isLoading)
    return (
      <HStack w="full" justifyContent={"center"} alignItems={"center"}>
        <Spinner />
      </HStack>
    );

  const isInWaitlist = hasBuyOrder || hasSellOrder;

  return (
    <Button
      onClick={onClick}
      w={["auto", "full"]}
      variant={isInWaitlist ? "outline" : "solid"}
      flexGrow={1}
      _hover={{
        backgroundColor: isInWaitlist
          ? ["white", "gray.100"]
          : ["black", "gray.700"],
        color: isInWaitlist ? ["black", "black"] : ["white", "white"],
      }}
      sx={{
        borderRadius: "md",
        backgroundColor: isInWaitlist ? ["black", "white"] : ["white", "black"],
        p: 0.5,
        px: 5,
        height: [16, 10],
        color: isInWaitlist ? ["white", "black"] : ["black", "white"],
      }}
      maxWidth={[48, "none"]}
      justifyContent={"center"}
      fontWeight={"bold"}
      fontFamily={"work sans"}
      textAlign={"center"}
      fontSize="12"
      wordBreak={["break-word", "initial"]}
      whiteSpace={["normal", "initial"]}
      {...props}
    >
      {hasBuyOrder
        ? "✅ On waitlist"
        : hasSellOrder
        ? "✅ Tickets listed"
        : "JOIN WAITLIST"}
    </Button>
  );
};

export default JoinWaitlistButton;
