import { Auth } from "aws-amplify";
import { API_NAMES, getCurrentParamsUrl } from "./utils";

const { analytics_domain } = getCurrentParamsUrl();

const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "CUSTOM",

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: { domain: "whitelabel.kydlabs.com" },
  },
  API: {
    endpoints: [
      {
        name: API_NAMES.ANALYTICS,
        endpoint: `https://a.${analytics_domain}.com/prod/events`,
        custom_header: async () => {
          return {
            "kyd-cookie": document ? document.cookie : "",
          };
        },
      },
      {
        name: API_NAMES.EVENT,
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: async () => {
          let authToken;
          try {
            authToken = (await Auth.currentSession())
              .getIdToken()
              .getJwtToken();
          } catch (error) {
            //authToken = Cache.getItem("idToken");
          }

          return {
            Authorization: authToken,
            "x-api-key": process.env.REACT_APP_API_KEY,
            "kyd-cookie": document ? document.cookie : "",
          };
        },
      },
      {
        name: API_NAMES.ADMIN,
        endpoint: process.env.REACT_APP_ADMIN_API_ENDPOINT,
        custom_header: async () => {
          let authToken;
          try {
            authToken = (await Auth.currentSession())
              .getIdToken()
              .getJwtToken();
          } catch (error) {
            //authToken = Cache.getItem("idToken");
          }

          return {
            Authorization: authToken,
            "x-api-key": process.env.REACT_APP_ADMIN_API_KEY,
          };
        },
      },
      {
        name: API_NAMES.SCANNER,
        endpoint: process.env.REACT_APP_SCANNER_API_ENDPOINT,
        custom_header: async () => {
          return {
            "x-api-key": process.env.REACT_APP_SCANNER_API_KEY,
          };
        },
      },
    ],
  },
};
export default config;
