import { BaseTransport } from "@amplitude/analytics-core";
import { Payload, Response, Transport } from "@amplitude/analytics-types";
import actions from "./actions";

export default class AmplitudeTransport
  extends BaseTransport
  implements Transport
{
  async send(serverUrl: string, payload: Payload): Promise<Response | null> {
    /* istanbul ignore if */
    const res = await actions.ANALYTICS.trackEvents(payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return this.buildResponse(res);
  }
}
