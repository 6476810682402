import { useCallback, useState } from "react";

export const useErrors = (errorsDefault = {}) => {
  const [errors, setErrors] = useState(errorsDefault);

  const updateErrors = useCallback(
    (error: Record<string, any>) => {
      setErrors({
        ...errors,
        ...error,
      });
    },
    [errors, setErrors]
  );

  const cleanError = useCallback(
    (key: string) => {
      const newErrors = JSON.parse(JSON.stringify(errors));

      delete newErrors[key];
      setErrors(newErrors);
    },
    [errors, setErrors]
  );

  return [errors, updateErrors, cleanError] as const;
};