import { useEffect, useState } from "react";
import {
  Text,
  Stack,
  Button,
  HStack,
  VStack,
  Box,
  Input,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { KYDEvent as IKYDEvent, KYDEvent } from "@common/interfaces/KYDEvent";
import { KYDCartV2 } from "@common/interfaces/KYDCart";
import { FaChild } from "react-icons/fa";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "./Billing";
import Markdown from "react-markdown";

function Attendee({ kydEvent, cart }: { kydEvent: KYDEvent; cart: KYDCartV2 }) {
  const [newAttendeeMode, setNewAttendeeMode] = useState<boolean>(true);
  const {
    register,
    formState: { errors },
  } = useFormContext<FormInputs>(); // retrieve all hook methods

  useEffect(() => {
    if (cart) {
      if (cart.first_name && cart.last_name && cart.email) {
        setNewAttendeeMode(false);
      }
    }
  }, [cart]);

  const renderers = {
    a: ({ href, children }) => (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#3182ce",
          textDecoration: "underline",
        }}
      >
        {children}
      </a>
    ),
  };

  return (
    <VStack w="100%" spacing={4} textAlign={"left"}>
      <Text w="100%" fontSize={"xl"} fontWeight="bold">
        {kydEvent.display_mode === "normal" ? "Pay" : "Attendee Information"}
      </Text>
      {kydEvent.display_options?.checkout_disclosure ? (
        <Stack
          fontSize={"sm"}
          p={3}
          borderRadius={"lg"}
          borderWidth={"1px"}
          borderColor={"gray.100"}
        >
          <Text fontWeight={"medium"}>Note from the organizer:</Text>
          <Markdown components={renderers}>
            {kydEvent.display_options?.checkout_disclosure}
          </Markdown>
        </Stack>
      ) : null}
      {newAttendeeMode ? (
        <Stack w="100%">
          <Stack w="100%" spacing={4} direction={["column", null, "row"]}>
            <Stack w={["100%", null, "50%"]} spacing={0}>
              <Text fontSize={"sm"}>First Name *</Text>
              <Input
                h="40px"
                {...register("first_name", {
                  required: "First name required",
                })}
                placeholder="First Name"
              />

              {errors.first_name && (
                <Text fontSize={"xs"} color="red.500">
                  {errors.first_name.message}
                </Text>
              )}
            </Stack>
            <Stack w={["100%", null, "50%"]} spacing={0}>
              <Text fontSize={"sm"}>Last Name *</Text>
              <Input
                h="40px"
                {...register("last_name", {
                  required: "Last name required",
                })}
                placeholder="Last Name"
              />
              {errors.last_name && (
                <Text fontSize={"xs"} color="red.500">
                  {errors.last_name.message}
                </Text>
              )}
            </Stack>
          </Stack>
          <Stack w="100%" spacing={4} direction={["column", null, "row"]}>
            <Stack w="100%" spacing={0}>
              <Text fontSize={"sm"}>Email *</Text>
              <Input
                h="40px"
                {...register("email", {
                  required: "Email required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Invalid email format",
                  },
                })}
                placeholder="Email"
              />
              {errors.email && (
                <Text fontSize={"xs"} color="red.500">
                  {errors.email.message}
                </Text>
              )}
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack w="100%">
          <Stack spacing={0}>
            <HStack spacing={1}>
              <Text fontWeight={"medium"}>{cart?.first_name}</Text>
              <Text fontWeight={"medium"}>{cart?.last_name}</Text>
            </HStack>
            <Text>{cart?.email}</Text>
          </Stack>

          <HStack
            cursor={"pointer"}
            onClick={() => setNewAttendeeMode(!newAttendeeMode)}
          >
            <Icon color="blue.500" as={FaChild} />
            <Text color="blue.500">Change attendee</Text>
          </HStack>
        </Stack>
      )}
    </VStack>
  );
}

export default Attendee;
