import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import TicketTypeWidget from "./TicketTypeWidget";
import { useContext, useEffect, useRef } from "react";
import { DialogProvider, createDialogContext } from "../Common/Dialog";

export const WaitlistPaymentMethodConfirmationModalContext =
  createDialogContext();

const WaitlistPaymentMethodConfirmationModal = ({
  onConfirm,
  onCancel,
  url,
}) => {
  const iframeRef = useRef<null | HTMLIFrameElement>(null);
  const { isOpen, close, title, text } = useContext(
    WaitlistPaymentMethodConfirmationModalContext
  );

  const onCloseInternal = () => {
    close();
    onCancel();
  };

  const onConfirmInternal = async () => {
    await onConfirm();
    close();
  };

  useEffect(() => {
    const onMessage = (e) => {
      if (e.data === "3DS-authentication-complete") {
        close();
      }
    };

    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onCloseInternal} isCentered size="5xl">
      <ModalOverlay />
      <ModalContent
        mb={[0, "auto"]}
        borderBottomLeftRadius={[0, "md"]}
        borderBottomRightRadius={[0, "md"]}
      >
        <ModalHeader paddingTop={4}>
          <VStack gap={0} width={"full"}>
            <Text w="full">{title}</Text>
          </VStack>
        </ModalHeader>
        <ModalBody textAlign={"left"}>
          <iframe
            src={url}
            ref={iframeRef}
            width="100%"
            height="100%"
            style={{ minHeight: "80vh" }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WaitlistPaymentMethodConfirmationModal;

export const withWaitlistPaymentMethodConfirmationModal =
  <T extends object>(Component: React.ComponentType<T>) =>
  (props: T) => {
    return (
      <DialogProvider context={WaitlistPaymentMethodConfirmationModalContext}>
        <Component {...(props as T)} />
      </DialogProvider>
    );
  };
