import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext } from "react";
import { createDialogContext, createWithModalWrapperComponent } from "./Dialog";
import logoFooterDark from "../darksmall.png";
import { useNavigate } from "react-router-dom";
import KYDEvent from "src/KYDEvent";

export const FullScreenModalContext = createDialogContext({
  actions: [
      {
        text: "Back to the event",
        type: 'confirm'
      },
  ]
});

export const withFullScreenModal = createWithModalWrapperComponent(
  FullScreenModalContext
);

const FullScreenModal = ({ eventId }: { eventId: string }) => {
  const navigate = useNavigate();
  const {
    isOpen,
    text,
    title,
    actions,
    close,
  } = useContext(FullScreenModalContext);

  return (
    <>
      <Modal isOpen={isOpen} onClose={close} isCentered size="full">
        <ModalOverlay />
        <ModalContent
          mb={[0, "auto"]}
          borderBottomLeftRadius={[0, "md"]}
          borderBottomRightRadius={[0, "md"]}
        >
          <ModalHeader paddingTop={2}></ModalHeader>
          <ModalBody textAlign={"center"}>
            <VStack spacing={5} my={5} pt={5}>
              <Image maxW="60px" src={logoFooterDark} />
              <Text fontWeight={"bold"} fontSize="xl">
                {title}
              </Text>
              <Text
                textAlign={"center"}
                fontSize={"sm"}
                color="gray.600"
                maxW={"300px"}
              >
                {text}
              </Text>
              <Box
                px={1}
                display={{
                  base: "none",
                  md: "block",
                }}
                pt={8}
              >
                {actions.map((action) => (
                  <Button
                    variant="kydDark"
                    w={actions.length === 1 ? "full" : "auto"}
                    onClick={() => {
                      if (action.onClick) {
                        action.onClick();
                        close();
                        return;
                      }

                      const searchParams = new URLSearchParams(
                        window.location.search
                      );
                      close();
                      navigate(
                        `/e/${eventId}/wallet?${searchParams.toString()}`
                      );
                    }}
                  >
                    {action.text}
                  </Button>
                ))}
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter
            display={{
              base: "block",
              md: "none",
            }}
          >
            {actions.map((action) => (
              <Button
                variant="kydDark"
                w={actions.length === 1 ? "full" : "auto"}
                onClick={() => {
                  if (action.onClick) {
                    action.onClick();
                    close();
                    return;
                  }

                  const searchParams = new URLSearchParams(
                    window.location.search
                  );
                  close();
                  navigate(`/e/${eventId}/wallet?${searchParams.toString()}`);
                }}
              >
                {action.text}
              </Button>
            ))}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FullScreenModal;
