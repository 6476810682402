import {
  Text,
  Stack,
  HStack,
  useClipboard,
  useToast,
  Box,
  chakra,
} from "@chakra-ui/react";
import {
  KYDTicketType as ITicketType,
  KYDTicketType,
} from "@common/interfaces/KYDTicket";
import { useCollapse } from "@collapsed/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { KYDEvent } from "@common/interfaces/KYDEvent";
import { Wallet } from "@common/interfaces/KYDUser";
import {
  KYDCart,
  KYDCartLineItemV2,
  KYDCartLineItem,
  KYDCartV2,
} from "@common/interfaces/KYDCart";
import TicketTypeQuantityTd from "./TicketTypeQuantityTd";
import TicketMobileTypeCell from "./TicketTypeMobileTypeCell";

interface KYDTicketTypesProps {
  kydEvent: KYDEvent;
  cart: KYDCart | KYDCartV2 | null;
  ticketTypes: ITicketType[];
  wallet: Wallet | null;
  quantities: any;
  onJoinWaitlist: (ticketType: KYDTicketType) => void;
  updateQuantity: (
    ticket_type: ITicketType,
    action: string,
    value?: number
  ) => void;
}

function TicketTypeDescriptionDesktop(props: any) {
  const { getCollapseProps, setExpanded, isExpanded } = useCollapse({
    defaultExpanded: false,
    collapsedHeight: 20,
  });

  return (
    <Box onClick={() => setExpanded(!isExpanded)}>
      {!props.maxLimitReached && props.description ? (
        <Text
          {...getCollapseProps()}
          lineHeight={5}
          fontSize="xs"
          minH={"20px"}
        >
          {props.description}
        </Text>
      ) : null}
      {props.description &&
        props.description.length > 80 &&
        !props.maxLimitReached && (
          <Text cursor="pointer" fontSize={"xs"} fontWeight={"bold"}>
            see {isExpanded ? "less" : "more"}
          </Text>
        )}
    </Box>
  );
}

function KYDTicketTypes(props: KYDTicketTypesProps) {
  const { onCopy } = useClipboard(props.wallet?.share_link || "");
  const toast = useToast();
  const hasPaidTicketTypes =
    props.kydEvent.ticket_types.filter((tt) => !tt.free).length > 0;
  return (
    <Stack w="100%">
      <TableContainer w="100%">
        <Table
          whiteSpace={{ base: "inherit", md: "normal" }}
          size={"lg"}
          variant={{ base: "unstlyed", md: "simple" }}
        >
          <Thead>
            <Tr>
              <Th
                px={2}
                display={{ base: "none", md: "table-cell" }}
                textAlign={"left"}
              >
                Ticket Type
              </Th>
              {hasPaidTicketTypes ||
              props.kydEvent.display_mode === "normal" ? (
                <Th
                  px={2}
                  display={{ base: "none", md: "table-cell" }}
                  textAlign={"right"}
                >
                  Price
                </Th>
              ) : null}

              {props.kydEvent.display_options?.price_mode !== "inclusive" &&
              (hasPaidTicketTypes ||
                props.kydEvent.display_mode === "normal") ? (
                <Th
                  px={2}
                  display={{ base: "none", md: "table-cell" }}
                  textAlign={"right"}
                >
                  Fees
                </Th>
              ) : null}

              <Th
                display={{ base: "none", md: "table-cell" }}
                textAlign={"right"}
                w="200px"
              >
                Quantity
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.ticketTypes.map((tt) => {
              let maxLimitReached = false;
              let tickets = 0;
              if (props.wallet) {
                tickets += props.wallet.tickets.filter(
                  (ticket) => ticket.ticket_type_id === tt.id
                ).length;
              }

              if (props.cart && props.cart.version === "v1") {
                tickets += (props.cart as KYDCart).line_items.filter((li) => {
                  return (
                    li.type === "TICKET" &&
                    (li as KYDCartLineItem).ticket_type_id === tt.id
                  );
                }).length;
              } else if (props.cart && props.cart.version === "v2") {
                tickets += (props.cart as KYDCartV2).line_items.filter((li) => {
                  return (li as KYDCartLineItemV2).entity_type_id === tt.id;
                }).length;
              }

              maxLimitReached = tickets >= tt.limit;

              return (
                <Tr key={tt.id}>
                  <Td
                    px={2}
                    style={{ wordWrap: "break-word" }}
                    display={{ base: "none", md: "table-cell" }}
                  >
                    <Stack
                      whiteSpace={"pre-wrap"}
                      spacing={1}
                      textAlign={"left"}
                      w="100%"
                    >
                      <Text fontWeight={"bold"} fontSize="lg">
                        {tt.name}
                      </Text>

                      <TicketTypeDescriptionDesktop
                        maxLimitReached={maxLimitReached}
                        description={tt.description}
                      />
                    </Stack>
                  </Td>
                  {hasPaidTicketTypes ||
                  props.kydEvent.display_mode === "normal" ? (
                    <Td
                      px={2}
                      maxW={
                        props.kydEvent.display_options?.show_currency
                          ? "200px"
                          : "150px"
                      }
                      minW={"120px"}
                      textAlign={"right"}
                      display={{ base: "none", md: "table-cell" }}
                    >
                      {tt.sold_out ? (
                        <Text></Text>
                      ) : (
                        <Stack spacing={0}>
                          <HStack spacing={1} justify={"right"}>
                            <Text>{tt.free ? "FREE" : tt.display_price}</Text>
                            <Text fontSize={"xs"}>
                              {props.kydEvent.display_options?.show_currency
                                ? props.kydEvent.currency
                                : ""}
                            </Text>
                          </HStack>
                          {props.kydEvent.display_options?.price_mode ===
                          "inclusive" ? (
                            <Stack spacing={-2}>
                              <Text color="gray.600" fontSize={"xs"}>
                                All-In price.
                              </Text>
                              <Text color="gray.600" fontSize={"xs"}>
                                No surprises.
                              </Text>
                            </Stack>
                          ) : null}
                        </Stack>
                      )}
                    </Td>
                  ) : null}
                  {props.kydEvent.display_options?.price_mode !== "inclusive" &&
                  (hasPaidTicketTypes ||
                    props.kydEvent.display_mode === "normal") ? (
                    <Td
                      px={2}
                      maxW={"120px"}
                      textAlign={"right"}
                      display={{ base: "none", md: "table-cell" }}
                    >
                      {tt.free ? "FREE" : tt.display_fees_total_price}
                    </Td>
                  ) : null}

                  <Td
                    textAlign={
                      maxLimitReached
                        ? "left"
                        : tt.sold_out
                        ? "right"
                        : "center"
                    }
                    display={{ base: "none", md: "table-cell" }}
                    p={0}
                  >
                    <TicketTypeQuantityTd
                      isWalletLoading={false}
                      kydEvent={props.kydEvent}
                      ticketType={{ ...tt, maxLimitReached }}
                      quantities={props.quantities}
                      wallet={props.wallet}
                      updateQuantity={props.updateQuantity}
                      onJoinWaitlist={props.onJoinWaitlist}
                    />
                  </Td>
                  <Td
                    px={0}
                    py={2}
                    display={{ base: "table-cell", md: "none" }}
                  >
                    <TicketMobileTypeCell
                      wallet={props.wallet}
                      ticketType={tt}
                      maxLimitReached={maxLimitReached}
                      shareLink={props.wallet?.share_link}
                      onCopy={onCopy}
                      quantities={props.quantities}
                      kydEvent={props.kydEvent}
                      updateQuantity={props.updateQuantity}
                      onJoinWaitlist={props.onJoinWaitlist}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default KYDTicketTypes;
