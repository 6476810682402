import { HStack, Image, Stack, Text } from "@chakra-ui/react";

const EventFooter = ({ faq_link, logoFooterDark, showIntercom }) => {
  return (
    <HStack
      display={{ base: "none", md: "flex" }}
      borderTop={"1px"}
      w="full"
      borderTopColor="gray.200"
      justify={"space-between"}
      p={3}
      marginTop={'0 !important'}
      roundedBottom={"lg"}
      bg="gray.50"
    >
      <HStack spacing={4}>
        <a
          href="https://kydlabs.com?ref=event"
          target="_blank"
          rel="noreferrer"
        >
          <Image maxW={"60px"} src={logoFooterDark} />
        </a>
        <Text fontSize={"xs"} textDecor={"underline"}>
          <a
            href="https://www.iubenda.com/terms-and-conditions/22517592"
            target={"_blank"}
            rel="noreferrer"
          >
            Terms Of Service
          </a>
        </Text>
        <Text fontSize={"xs"} textDecor={"underline"}>
          <a
            href="https://www.iubenda.com/privacy-policy/22517592"
            target={"_blank"}
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Text>
        {faq_link && (
          <Text fontSize={"xs"} textDecor={"underline"}>
            <a href={faq_link} target={"_blank"} rel="noreferrer">
              FAQ
            </a>
          </Text>
        )}
        <Text
          onClick={() => showIntercom()}
          fontSize={"xs"}
          textDecor={"underline"}
          cursor="pointer"
        >
          Help
        </Text>
      </HStack>
      <Stack spacing={0}>
        <Text textAlign={"right"} color="gray.600" fontSize={"xs"}>
          © {new Date().getFullYear()} KYD Labs Inc.
        </Text>
        <Text textAlign={"right"} color="gray.400" fontSize={"xs"}>
          v{process.env.REACT_APP_VERSION}
        </Text>
      </Stack>
    </HStack>
  );
};

export default EventFooter;
