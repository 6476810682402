import { Box, Button, Image, Text, VStack, chakra } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import Billing from "../Billing";
import { Elements } from "@stripe/react-stripe-js";
import Attendee from "../Attendee";
import { KYDCartV2 } from "@common/interfaces/KYDCart";
import { KYDEvent } from "@common/interfaces/KYDEvent";
import { useEffect, useRef, useState } from "react";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { CheckoutParams } from "..";
import HighlightedTitleCard from "../HighlightedTitleCard";
import WaitlistTopCard, { InlineText } from "./WaitlistTopCard";
import { useDialogWithOptions } from "src/Common/Dialog";
import { WaitlistPaymentMethodConfirmationModalContext } from "../WaitlistPaymentMethodConfirmation";

type FormInputs = {
  first_name: string;
  last_name: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  country: string;
  store_for_future_use: boolean;
  stored_cvv: string;
  payment_method_id: string | null;
};

const WaitlistCheckout = ({
  cart,
  kydEvent,
  stripeObject,
  onCreateWaitlistBuyOrder,
  stripeRef,
  elementsRef,
  radarRef,
  isLoading = false,
}: {
  cart: KYDCartV2 | null;
  kydEvent: KYDEvent;
  stripeObject: Stripe;
  onCreateWaitlistBuyOrder: (data: CheckoutParams) => void;
  stripeRef: React.MutableRefObject<Stripe>;
  elementsRef: React.MutableRefObject<StripeElements>;
  radarRef: React.MutableRefObject<any>;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const methods = useForm<FormInputs>();

  const [paymentFormComplete, setPaymentForComplete] = useState(false);

  useEffect(() => {
    if (!cart) {
      console.log("No cart");
      navigate(`/e/${kydEvent.id}`);
    }
  }, [navigate, kydEvent, cart]);

  useEffect(() => {
    if (cart) {
      if (cart.first_name) methods.setValue("first_name", cart.first_name);
      if (cart.last_name) methods.setValue("last_name", cart.last_name);
      if (cart.email) methods.setValue("email", cart.email);

      if (
        cart.stored_payment_methods &&
        cart.stored_payment_methods.length > 0
      ) {
        console.log(
          "Set payment method id: ",
          cart.stored_payment_methods[0].id
        );
        methods.setValue(
          "payment_method_id",
          cart.stored_payment_methods[0].id
        );
      } else {
        methods.setValue("payment_method_id", null);
      }
    }
  }, [cart]);

  if (!cart) {
    return;
  }

  return (
    <VStack spacing={4}>
      <WaitlistTopCard title={<Text>GET ON THE LIST</Text>}>
        <Text>
          <InlineText>
            When you add your credit card, you're giving us the ability to
            instantly secure tickets for you as soon as they become available.{" "}
          </InlineText>
          <InlineText fontWeight={"bold"}>
            The {cart.display_total} charge (including sales tax) will only be applied if we
            successfully obtain tickets for you prior to the event.{" "}
          </InlineText>
          <InlineText fontStyle={"italic"}>
            Rest assured, you have the flexibility to remove yourself from the
            waitlist at any time before the event takes place.
          </InlineText>
        </Text>
      </WaitlistTopCard>
      <VStack
        w="full"
        backgroundColor={"white"}
        p={4}
        borderTopRightRadius={"lg"}
        borderTopLeftRadius={"lg"}
      >
        <FormProvider {...methods}>
          {cart && cart.bypass_billing ? (
            <Attendee kydEvent={kydEvent} cart={cart} />
          ) : (
            <Elements
              stripe={stripeObject}
              options={{
                mode: "setup",
                currency: cart?.currency.toLowerCase(),
                loader: "never",
                paymentMethodCreation: "manual",
              }}
            >
              <Billing
                kydEvent={kydEvent}
                cart={cart}
                onExpressCheckout={methods.handleSubmit((data) => {
                  onCreateWaitlistBuyOrder({ formData: data, type: "express" });
                })}
                paymentFormComplete={paymentFormComplete}
                setPaymentFormComplete={setPaymentForComplete}
                stripeRef={stripeRef}
                elementsRef={elementsRef}
                radarRef={radarRef}
              />
            </Elements>
          )}
          <VStack w="100%">
            <Text fontSize={"sm"} color="gray.800" py={2}>
              Your card will be charged{" "}
              <InlineText fontWeight={"bold"} fontSize={16}>
                {cart.display_total}
              </InlineText>
              {kydEvent.display_options?.show_currency
                ? ` ${kydEvent.currency}`
                : ""}{" "}
              if we get your tickets.
            </Text>
            <Button
              w="100%"
              maxW={"lg"}
              isDisabled={
                !stripeRef.current || !paymentFormComplete || isLoading
              }
              isLoading={!stripeRef || isLoading}
              bg={"green.500"}
              _hover={{ bg: "gray.800" }}
              onClick={methods.handleSubmit((data) => {
                onCreateWaitlistBuyOrder({ formData: data, type: "card" });
              })}
              color="white"
            >
              Join waitlist
            </Button>
          </VStack>
        </FormProvider>
      </VStack>
    </VStack>
  );
};

export default WaitlistCheckout;
