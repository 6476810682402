import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useEditable,
} from "@chakra-ui/react";
import { act, useCallback, useContext, useEffect, useState } from "react";
import { DialogProvider, createDialogContext } from "../Common/Dialog";
import { KYDTicketType } from "@common/interfaces/KYDTicket";
import TicketTypeWidget from "./TicketTypeWidget";
import {
  KYDSellableWaitlistTicketType,
  Wallet,
} from "@common/interfaces/KYDUser";
import { groupBy } from "../utils";

export const JoinWaitlistModalContext = createDialogContext();

const JoinWaitlistModal = ({
  onCreateSellOrder = () => {},
  onCreateBuyOrder = () => {},
  onCancel = () => {},
  ticketTypes = [],
  isLoading,
  action,
  wallet,
}: {
  onCreateSellOrder?: ({
    ticketTypeId,
    ticketIds,
  }: {
    ticketTypeId: string;
    ticketIds: string[];
  }) => void;
  onCreateBuyOrder?: ({
    ticketTypeId,
    quantity,
  }: {
    ticketTypeId: string;
    quantity: number;
  }) => void;
  onCancel?: () => void;
  ticketTypes: KYDTicketType[];
  isLoading: boolean;
  action: "buy" | "sell" | null;
  wallet: Wallet;
}) => {
  const { isOpen, close, title, text } = useContext(JoinWaitlistModalContext);

  const [currentTicketType, setCurrentTicketType] = useState<{
    ticket_type_id: string;
    quantity: number;
  } | null>(null);

  const resetCount = () => {
    setCurrentTicketType(null);
  };

  useEffect(() => {
    if (!ticketTypes || ticketTypes.length === 0) {
      close();
    }
  }, [ticketTypes]);

  const setTicketTypeCount = useCallback(
    (ticketTypeId: string, quantity: number) => {
      if (quantity === 0) {
        setCurrentTicketType(null);
      } else {
        setCurrentTicketType(() => ({
          ticket_type_id: ticketTypeId,
          quantity,
        }));
      }
    },
    [currentTicketType, setCurrentTicketType]
  );

  const onCloseInternal = () => {
    // setCount(0);
    resetCount();
    close();
    onCancel();
  };

  const onConfirmInternal = async () => {
    if (action === "buy") {
      await onCreateBuyOrder({
        ticketTypeId: currentTicketType.ticket_type_id,
        quantity: currentTicketType.quantity,
      });
    } else if (action === "sell") {
      const ticket_type = wallet?.valid_waitlist_ticket_types.find(
        (tt) => tt.id === currentTicketType.ticket_type_id
      );
      const tickets = ticket_type.ticket_ids.slice(
        0,
        currentTicketType.quantity
      );
      await onCreateSellOrder({
        ticketTypeId: currentTicketType.ticket_type_id,
        ticketIds: tickets,
      });
    }

    close();
    resetCount();
  };

  const buttonText = {
    buy: "JOIN WAITLIST",
    sell: `LIST ${
      currentTicketType ? currentTicketType.quantity : 0
    } TICKET(S)`,
  };

  const hasCountChanged = currentTicketType && currentTicketType.quantity > 0;

  useEffect(() => {
    if (action === null && isOpen) {
      console.warn("JoinWaitlistModal with no action");
    }
  }, [action, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={isLoading ? () => {} : onCloseInternal}
      isCentered
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent
        pos={["absolute", "relative"]}
        bottom={[0, "auto"]}
        mb={[0, "auto"]}
        borderBottomLeftRadius={[0, "md"]}
        borderBottomRightRadius={[0, "md"]}
      >
        <ModalCloseButton />
        <ModalHeader paddingTop={4}>
          <VStack gap={0} width={"full"}>
            <Text w="full">{title}</Text>
          </VStack>
        </ModalHeader>
        <ModalBody textAlign={"left"}>
          <VStack spacing={2}>
            <Text w="full" color="gray.600" fontSize={14}>
              {text}
            </Text>
            {action === "buy"
              ? ticketTypes.map((ticketType) => (
                  <TicketTypeWidget
                    ticketType={ticketType}
                    onChangeCount={(quantity) =>
                      setTicketTypeCount(ticketType.id, quantity)
                    }
                    disabled={
                      isLoading ||
                      (currentTicketType &&
                        ticketType &&
                        currentTicketType.ticket_type_id !== ticketType.id)
                    }
                    maximum={ticketType.limit}
                    hidePrice={false}
                    isListed={
                      !!wallet?.buy_waitlist_orders.find((wo) =>
                        ticketType.pool
                          ? wo.pool_id === ticketType.pool?.id
                          : wo.pool_id === ticketType.id
                      )
                    }
                  />
                ))
              : null}
            {action === "sell"
              ? wallet?.valid_waitlist_ticket_types.map((ticketType) => (
                  <TicketTypeWidget
                    ticketType={{
                      id: ticketType.id,
                      name: ticketType.name,
                      description: ticketType.description,
                    }}
                    onChangeCount={(quantity) =>
                      setTicketTypeCount(ticketType.id, quantity)
                    }
                    disabled={
                      isLoading ||
                      (currentTicketType &&
                        ticketType &&
                        currentTicketType.ticket_type_id !== ticketType.id)
                    }
                    maximum={ticketType.quantity}
                    hidePrice={true}
                    isListed={
                      !!wallet?.sell_waitlist_orders.find(
                        (wo) => wo.pool_id === ticketType.pool_id
                      )
                    }
                  />
                ))
              : null}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onConfirmInternal}
            isDisabled={!hasCountChanged || isLoading}
            disabled={!hasCountChanged || isLoading}
            isLoading={isLoading}
            sx={{
              width: "full",
              backgroundColor: "lightGreen",
              borderRadius: "xl",
              borderWidth: 2,
              borderColor: "darkGreen",
              color: "black",
              p: 6,
              fontSize: 18,
              fontWeight: "bold",
              _hover: {
                backgroundColor: "darkGreen",
              },
            }}
          >
            {buttonText[action]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const withWaitlistModal =
  <T extends object>(Component: React.ComponentType<T>) =>
  (props: T) => {
    return (
      <DialogProvider context={JoinWaitlistModalContext}>
        <Component {...(props as T)} />
      </DialogProvider>
    );
  };

export default JoinWaitlistModal;
