import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  chakra,
  HStack,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { KYDTicketType } from "@common/interfaces/KYDTicket";

const InlineText = chakra.span;

const TicketTypeQuantityWidget = ({
  updateQuantity,
  tt,
  quantities,
}: {
  updateQuantity: (
    ticket_type: KYDTicketType,
    action: string,
    value?: number
  ) => void;
  tt: KYDTicketType;
  quantities: any;
}) => {
  const canSubtract = (quantities[tt.id] || 0) - tt.increment >= 0;
  const canAdd =
    (quantities[tt.id] || 0) + tt.increment <= Math.min(tt.limit, tt.remaining);

  return (
    <VStack>
      <>
        <HStack w="100%" justify={"flex-end"}>
          <IconButton
            onClick={() => updateQuantity(tt, "sub")}
            aria-label="Subtract"
            icon={<MinusIcon />}
            disabled={!canSubtract}
            isDisabled={!canSubtract}
          />
          <Text fontWeight={"bold"} textAlign={"center"} minW={"20px"}>
            {quantities[tt.id] || 0}
          </Text>
          <IconButton
            onClick={() => updateQuantity(tt, "add")}
            aria-label="Add"
            icon={<AddIcon />}
            disabled={!canAdd}
            isDisabled={!canAdd}
          />
        </HStack>
        {tt.remaining <= tt.limit ? (
          <>
            <Text
              w="100%"
              textAlign={"right"}
              fontWeight={"medium"}
              fontSize={"xs"}
              color="red.500"
            >{`${tt.remaining} remaining`}</Text>
          </>
        ) : null}
      </>
    </VStack>
  );
};

export default TicketTypeQuantityWidget;
