import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs, { Dayjs } from "dayjs";
import { detect } from "detect-browser";
import * as amplitude from "@amplitude/analytics-browser";
import { KYDEvent, KYDPixels } from "@common/interfaces/KYDEvent";
import { AdminTicketType } from "@common/interfaces/KYDTicket";
import posthog from "posthog-js";
import Intercom, {
  hide as hideIntercom,
  show as showIntercom,
} from "@intercom/messenger-js-sdk";

import AmplitudeTransport from "./AmplitudeTransport";
import {
  AddToCart,
  InitiateCheckout,
  Purchase,
  CompleteRegistration,
} from "react-facebook-pixel";
import { sha256 } from "js-sha256";
import { CognitoUser } from "amazon-cognito-identity-js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

export type TieredTicketTypes = { [ticket_type_id: string]: AdminTicketType[] };

enum AUTH_STATES {
  LOADING = "loading",
  AUTHENTICATED = "authenticated",
  NEEDS_AUTH = "needs_auth",
  ONBOARDING = "onboarding",
}

enum ModalType {
  INACTIVE = 0,
  SECURING_TICKETS = 1,
  ADD_TO_CART_SUCCESS = 2,
  SIGN_IN_PHONE_NUMBER = 3,
  SIGN_IN_SMS_CODE = 4,
  SMS_CODE_EXPLAINER = 5,
  EMAIL_NAME_INPUT = 6,
  EXPIRED_CART = 7,
  CLEAR_CART_CONFIRM = 8,
  CLEARING_CART = 9,
  SHOW_WALLET = 10,
  TRANSFER_TICKETS_PHONE = 11,
  TRANSFER_TICKETS_INVALID_PHONE = 12,
  TRANSFER_TICKETS_TRANSFER_SUCCESS = 13,
  TRANSFER_TICKETS_SELECT = 14,
  TRANSFER_TICKETS_CONFIRM = 15,
  TRANSFER_TICKETS_SUCCESS = 16,
  CREATING_REMINDER = 17,
  LIGHTBOX = 18,
  CHECKING_OUT = 19,
  CHECK_OUT_SUCCESS = 20,
  CHECK_OUT_FAILED = 21,
  CHECK_OUT_TIMED_OUT = 22,
}

enum ScannerState {
  INACTIVE = 0,
  ACCESS_CODE_REQUIRED = 1,
  VALIDATING_ACCESS_CODE = 2,
  INITIALIZING = 3,
  READY = 4,
  PROCESSING = 5,
  VALIDATED = 6,
  INVALID_TICKETS = 400,
  CHECKING_IN = 9,
  CHECK_IN_SUCCESS = 10,
  CHECK_IN_FAILED = 11,
}

const API_NAMES = {
  EVENT: "KYDEVENT",
  SCANNER: "KYDSCANNER",
  ADMIN: "KYDADMIN",
  ANALYTICS: "ANALYTICS",
};

const setupIntercom = () => {
  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  });
};

const signinIntercom = ({
  user_id,
  email,
  first_name,
  last_name,
  hmac,
}: {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  hmac: string;
}) => {
  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    user_id,
    email,
    first_name,
    last_name,
    user_hash: hmac,
  });
};

const getCurrentParamsUrl = () => {
  const href = window.location.href;
  if (href) {
    const parts = href.split("?").shift()?.split("https://").pop()?.split("/");

    let analytics_domain;
    let event_id;
    let type;
    parts?.forEach((part, index) => {
      if (part.endsWith(".com") && index === 0) {
        analytics_domain = part.split(".")[1];
      } else if (index === 1 && part === "e" && parts.length > 2) {
        event_id = parts[2];
        type = "event";
      } else if (index === 1 && part === "bio") {
        event_id = parts[1];
        type = "bio";
      }
    });
    return { event_id, type, analytics_domain };
  }

  return {};
};

console.log(process.env.NODE_ENV);
const analytics = {
  init: async () => {
    posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
      api_host: `https://app.posthog.com`,
      debug: false,
    });

    await amplitude.init(process.env.REACT_APP_AMPLITUDE_ID!, undefined, {
      attribution: {
        trackPageViews: false,
      },
      appVersion: process.env.REACT_APP_VERSION!,
      transportProvider: new AmplitudeTransport(),
      logLevel: amplitude.Types.LogLevel.Verbose,
    });
  },
  setUser: (user_id: string, email?: string) => {
    try {
      amplitude.setUserId(user_id);
      posthog.identify(
        user_id, // Replace 'distinct_id' with your user's unique identifier
        { email } // optional: set additional user properties
      );
      if (email) {
        const identifyEvent = new amplitude.Identify();
        identifyEvent.set("email", email);
        amplitude.identify(identifyEvent);
      }
    } catch (err) {}
  },
  t: (name: string, props?: any) => {
    const browser = detect();
    const { event_id, type } = getCurrentParamsUrl();

    if (!props) {
      props = { event_id, type };
    }

    const updatedProps = {
      ...props,
      browser: browser && browser.name ? browser.name.toLowerCase() : "unknown",
    };

    const id = amplitude.getUserId();
    if (id) {
      const group = id.charCodeAt(0) % 2 === 0 ? "a" : "b";
      updatedProps.group = group;
    }
    try {
      amplitude.track(name, updatedProps);
      posthog.capture(name, updatedProps);
    } catch (err) {}
  },
  reset: () => {
    amplitude.reset();
    posthog.reset();
  },
};

const showErrorToast = (toast: any, message: string) => {
  toast({
    title: "Woops!",
    description: message,
    status: "error",
    duration: 5000,
    isClosable: true,
  });
};

const showSuccessToast = (toast: any, message: string) => {
  toast({
    title: "Success!",
    description: message,
    status: "success",
    duration: 5000,
    isClosable: true,
  });
};

const currentTz = () => {
  return dayjs.tz.guess();
};

/**
 * Checks to see if browser is currently running on iOS device
 */
const isiOSDevice = () => {
  const browser = detect();
  return browser && browser.os && browser.os.toLowerCase() === "ios";
};

/**
 * Checks native functions for Safari to see if the user is on Safari browser
 */
const isSafari = () => {
  const browser = detect();

  return (
    (browser && browser.name.toLowerCase() === "safari") ||
    (browser && browser.name.toLowerCase() === "ios")
  );
};

/**
 * Checks to see if the current browser is mobile chrome on iOS
 */
const isMobileChrome = () => {
  const browser = detect();

  if (browser) {
    const browserName = browser.name.toLowerCase();
    return isiOSDevice() && browserName === "crios";
  }

  return false;
};

/**
 * Checks to see if the current browser is mobile Safari
 */
const isMobileSafari = () => {
  const browser = detect();

  if (browser) {
    const browserName = browser.name.toLowerCase();
    return isiOSDevice() && (browserName === "ios" || browserName === "safari");
  }

  return false;
};

/**
 * Checks to see if the current browser is on a mobile device
 */
const isMobileDevice = () => {
  const { userAgent } = navigator;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );
};

const shortAddress = (address: string) => {
  return `${address.slice(0, 5)}...${address.slice(address.length - 5)}`;
};

const loadGoogleMapsAPI = (key: string, callback?: () => void) => {
  const existingScript = document.getElementById("gmaps-api");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
    script.id = "gmaps-api";
    script.async = true;
    document.head.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else if (callback) {
    callback();
  }
};

//24175242

const loadHubspotPixel = (key: string, callback?: (type: string) => void) => {
  const existingScript = document.getElementById("hs-script-loader");
  if (!existingScript) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${key}.js`;
    script.id = "hs-script-loader";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback("hubspot");
    };
  } else if (callback) {
    callback("hubspot");
  }
};

const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const isFacebookPixelInstalled = () => {
  //@ts-ignore
  return typeof window !== "undefined" && window.fbq;
};

const isSnapchatPixelInstalled = () => {
  //@ts-ignore
  return typeof window !== "undefined" && window.snaptr;
};

const isTikTokPixelInstalled = () => {
  return (
    typeof window !== "undefined" &&
    (window as any).ttq &&
    (window as any).ttq.track &&
    (window as any).ttq.identify
  );
};

const isHivePixelInstalled = () => {
  return typeof window !== "undefined" && (window as any).HIVE_SDK;
};

const isTwitterPixelInstalled = () => {
  //@ts-ignore
  return typeof window !== "undefined" && window.twq;
};

const pixel_trackPurchase = (
  data?: Purchase,
  eventId?: string | null,
  twitterEventId?: string | null,
  currentUser?: CognitoUser | null,
  cartId?: string
) => {
  try {
    //@ts-ignore
    if (isHivePixelInstalled()) {
      //@ts-ignore
      window.HIVE_SDK("ticketingOrder", "update", {
        id: cartId, // unique id for this order as saved into your own database
        status: "completed", // one of: "started" (user has started order), "completed" (user has completed order)
      });
    }

    if (isFacebookPixelInstalled()) {
      //@ts-ignore
      window.fbq("track", "Purchase", data, { eventID: eventId }); // eslint-disable-line no-undef
    } else {
      console.log("FB Pixel not installed");
    }

    if (isTikTokPixelInstalled()) {
      //@ts-ignore
      window.ttq.track("PlaceAnOrder", data); // eslint-disable-line no-undef
    }

    if (isSnapchatPixelInstalled()) {
      //@ts-ignore
      window.snaptr("track", "PURCHASE", {
        currency: "USD",
        price: data?.value,
        item_category: data?.content_type,
        //@ts-ignore
        item_ids: data?.contents?.map((c) => c.id),
        client_dedup_id: eventId,
        transaction_id: eventId,
      });
    }

    if (isTwitterPixelInstalled() && twitterEventId) {
      if (data) {
        //@ts-ignore
        data.conversion_id = eventId;
      }

      if (currentUser) {
        currentUser.getUserAttributes((err, results) => {
          if (!err && results && results.length > 0) {
            const email = results.find((result) => result.Name === "email");

            //@ts-ignore
            data.email_address = email ? email.Value : null;
            //@ts-ignore
            data.phone_number = currentUser.getUsername();

            //@ts-ignore
            window.twq("event", twitterEventId, data); // eslint-disable-line no-undef
          } else {
            //@ts-ignore
            window.twq("event", twitterEventId, data); // eslint-disable-line no-undef
          }
        });
      } else {
        //@ts-ignore
        window.twq("event", twitterEventId, data); // eslint-disable-line no-undef
      }
    }
  } catch (err) {
    console.error(err);
  }
};

const pixel_pageView = () => {
  try {
    if (isTikTokPixelInstalled()) {
      //@ts-ignore
      window.ttq.track("ViewContent"); // eslint-disable-line no-undef
    }

    if (isSnapchatPixelInstalled()) {
      //@ts-ignore
      window.snaptr("track", "VIEW_CONTENT");
    }
  } catch (err) {
    console.error(err);
  }
};

const pixel_addToCart = (
  data?: AddToCart,
  eventId?: string | null,
  twitterEventId?: string | null
) => {
  try {
    if (isFacebookPixelInstalled()) {
      //@ts-ignore
      window.fbq("track", "AddToCart", data, eventId); // eslint-disable-line no-undef
    } else {
      console.log("FB Pixel not installed");
    }

    if (isTikTokPixelInstalled()) {
      //@ts-ignore
      window.ttq.track("AddToCart", data); // eslint-disable-line no-undef
    }

    if (isTwitterPixelInstalled() && twitterEventId) {
      if (data) {
        //@ts-ignore
        data.conversion_id = eventId;
      }
      //@ts-ignore
      window.twq("event", twitterEventId, data); // eslint-disable-line no-undef
    }

    if (isSnapchatPixelInstalled()) {
      //@ts-ignore
      window.snaptr("track", "ADD_CART", {
        currency: "USD",
        price: data?.value,
        //@ts-ignore
        item_ids: data?.contents?.map((c) => c.id),
      });
    }
  } catch (err) {
    console.error(err);
  }
};

const pixel_setReminder = (data?: any) => {
  try {
    if (isFacebookPixelInstalled()) {
      //@ts-ignore
      window.fbq("trackCustom", "SetReminder", data); // eslint-disable-line no-undef
    } else {
      console.log("FB Pixel not installed");
    }

    if (isTikTokPixelInstalled()) {
      //@ts-ignore
      window.ttq.track("SetReminder", data); // eslint-disable-line no-undef
    }

    if (isSnapchatPixelInstalled()) {
      //@ts-ignore
      window.snaptr("track", "SetReminder");
    }
  } catch (err) {
    console.error(err);
  }
};

const pixel_initiateCheckout = ({
  data,
  eventId,
  twitterEventId,
  kydEvent,
  cartId,
}: {
  data?: InitiateCheckout;
  eventId?: string | null;
  twitterEventId?: string | null;
  kydEvent?: KYDEvent;
  cartId?: string;
}) => {
  try {
    //@ts-ignore
    if (isHivePixelInstalled()) {
      const artist_names = kydEvent.performers?.map((p) => p.name).join(",");
      const genre_names = kydEvent.genres?.map((g) => g.name).join(",");
      //@ts-ignore
      window.HIVE_SDK(
        "ticketingOrder",
        "create",
        {
          id: cartId, // unique id for this order as saved into your own database
          status: "started", // one of: "started" (user has started order), "completed" (user has completed order)
          total_paid: data.value,
          event: {
            id: kydEvent ? kydEvent.id : null, // unique id for this event as saved into your own database
            name: kydEvent ? kydEvent.name : null,
            start_time: kydEvent ? kydEvent.start_at : null, // when does the event begin? in ISO 8601 format
            end_time: kydEvent ? kydEvent.end_at : null, // when does the event end? in ISO 8601 format
            url: window?.location?.href?.split("/cart")?.shift(),
            image_url: kydEvent ? kydEvent.image : null,
            artist_names,
            genre_names,
          },
          venue: {
            name:
              kydEvent && kydEvent.venues.length > 0
                ? kydEvent.venues[0].name
                : null,
            city:
              kydEvent && kydEvent.venues.length > 0
                ? kydEvent.venues[0].city
                : null,
            state:
              kydEvent && kydEvent.venues.length > 0
                ? kydEvent.venues[0].state
                : null,
            country:
              kydEvent && kydEvent.venues.length > 0
                ? kydEvent.venues[0].country
                : null,
          },
        },
        function () {
          // success callback, called after data is saved
        },
        function (data) {
          // failure callback, called if something goes wrong
          // error information is provided in the "data" param
        }
      );
    }

    if (isFacebookPixelInstalled()) {
      //@ts-ignore
      window.fbq("track", "InitiateCheckout", data, eventId); // eslint-disable-line no-undef
    } else {
      console.log("FB Pixel not installed");
    }

    if (isTikTokPixelInstalled()) {
      //@ts-ignore
      window.ttq.track("InitiateCheckout", data); // eslint-disable-line no-undef
    }

    if (isTwitterPixelInstalled() && twitterEventId) {
      if (data) {
        //@ts-ignore
        data.conversion_id = eventId;
      }
      //@ts-ignore
      window.twq("event", twitterEventId, data); // eslint-disable-line no-undef
    }

    if (isSnapchatPixelInstalled()) {
      //@ts-ignore
      window.snaptr("track", "START_CHECKOUT", {
        currency: "USD",
        price: data?.value,
        //@ts-ignore
        item_ids: data?.contents?.map((c) => c.id),
      });
    }
  } catch (err) {
    console.error(err);
  }
};

const pixel_completeRegistration = (data?: CompleteRegistration) => {
  try {
    if (isFacebookPixelInstalled()) {
      //@ts-ignore
      window.fbq("track", "CompleteRegistration", data); // eslint-disable-line no-undef
    } else {
      console.log("FB Pixel not installed");
    }

    if (isTikTokPixelInstalled()) {
      //@ts-ignore
      window.ttq.track("CompleteRegistration"); // eslint-disable-line no-undef
    }
  } catch (err) {
    console.error(err);
  }
};

const pixel_identify = ({
  user_id,
  email,
  phone_number,
  first_name,
  last_name,
}: {
  user_id?: string | null;
  email?: string | null;
  phone_number?: string | null;
  first_name?: string;
  last_name?: string;
}) => {
  try {
    //@ts-ignore
    if (email && window.HIVE_SDK) {
      //@ts-ignore
      window.HIVE_SDK(
        "emailSignup",
        {
          email: email,
          firstName: first_name,
          lastName: last_name,
          didOptIn: true, // optional
        },
        function (data) {
          // Success Callback
          // data.user contains info about the currently auth'ed user
          console.log(data);
        },
        function (data) {
          // Error Callback
          // data contains error information
          console.log(data);
        }
      );
    }
    //@ts-ignore
    if (isFacebookPixelInstalled() && window.fbq.getState) {
      //@ts-ignore
      const pixels_setup = window.fbq.getState();
      if (
        pixels_setup &&
        pixels_setup.pixels &&
        pixels_setup.pixels.length > 0
      ) {
        pixels_setup.pixels.forEach((pixel_data: any) => {
          const { em, ph, external_id } = pixel_data.userData;

          let hashed_email = email ? sha256(email) : null;
          let hashed_user_id = user_id ? sha256(user_id) : null;
          let hashed_phone_number = phone_number
            ? sha256(phone_number.slice(1))
            : null;

          if (em && !hashed_email) {
            hashed_email = em;
          }

          if (external_id && !hashed_user_id) {
            hashed_user_id = external_id;
          }

          if (ph && !hashed_phone_number) {
            hashed_phone_number = ph;
          }

          //@ts-ignore
          window.fbq("init", pixel_data.id, {
            external_id: hashed_user_id,
            em: hashed_email,
            ph: hashed_phone_number,
          });
        });
      }
    }

    if (isTikTokPixelInstalled()) {
      let hashed_email = email ? sha256(email) : null;
      let hashed_phone_number = phone_number ? sha256(phone_number) : null;
      //@ts-ignore
      window.ttq.identify({
        sha256_email: hashed_email,
        sha256_phone_number: hashed_phone_number,
        external_id: user_id,
      });
    }

    try {
      if (isSnapchatPixelInstalled()) {
        let hashed_email = email ? sha256(email.toLowerCase()) : null;
        let hashed_phone_number = phone_number
          ? sha256(phone_number.slice(1))
          : null;
        //@ts-ignore
        if (window.snaptr.context && window.snaptr.context.$) {
          //@ts-ignore
          const ids = Object.keys(window.snaptr.context.$);
          if (ids.length > 0) {
            ids.forEach((id) => {
              //@ts-ignore
              window.snaptr("init", id, {
                user_hashed_email: hashed_email,
                user_hashed_phone_number: hashed_phone_number,
              });
            });
          } else {
            analytics.t("pixels: error - no pixel id", {
              type: "snapchat",
            });
          }
        } else {
          analytics.t("pixels: error - no pixel code", { type: "snapchat" });
        }
      }
    } catch (err) {
      console.error("Could not identify snapchat pixel");
    }
  } catch (err) {
    console.error(err);
  }
};

const PIXELS = {
  purchase: pixel_trackPurchase,
  pageview: pixel_pageView,
  addtocart: pixel_addToCart,
  initiatecheckout: pixel_initiateCheckout,
  completeregistration: pixel_completeRegistration,
  identify: pixel_identify,
  setReminder: pixel_setReminder,
};

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
const dec2hex = (dec: number) => {
  return dec.toString(16).padStart(2, "0");
};

// generateId :: Integer -> String
const getRandomString = (len: number) => {
  var arr = new Uint8Array((len || 40) / 2);
  crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
};

const capitalized = (word: string) =>
  word ? word.charAt(0).toUpperCase() + word.slice(1) : "";

export enum EditModalType {
  INACTIVE,
  DESCRIPTION,
  TICKET_TYPE,
  PROMO_CODE,
  CREATE_AFFILIATES,
  VENUE,
  EDIT_TIERED_TICKET_TYPE,
  NAME,
  DATE,
  TIMELINE,
}

const formatDate = (date: Dayjs) => date.format("ddd MMM DD hh:mmA");

export function groupBy<T, K extends keyof any>(
  array: T[] = [],
  keyGetter: (item: T) => K
): Record<K, T[]> {
  return array.reduce((accumulator, item) => {
    const key = keyGetter(item);
    if (!accumulator[key]) {
      accumulator[key] = [];
    }
    accumulator[key].push(item);
    return accumulator;
  }, {} as Record<K, T[]>);
}

export const markdownToLineBreaks = (markdownText: string = "") => {
  // KUDOS: https://github.com/remarkjs/react-markdown/issues/278#issuecomment-1504554666
  try {
    return markdownText?.replace(/(\n\n)(?![*-])\n/gi, "&nbsp;\n ");
  } catch (err) {
    console.log(err);
  }
};

export {
  showErrorToast,
  showSuccessToast,
  isMobileDevice,
  currentTz,
  isSafari,
  isMobileSafari,
  isiOSDevice,
  isMobileChrome,
  shortAddress,
  AUTH_STATES,
  ModalType,
  ScannerState,
  analytics,
  API_NAMES,
  loadGoogleMapsAPI,
  scrollToTop,
  getRandomString,
  PIXELS,
  capitalized,
  getCurrentParamsUrl,
  formatDate,
  setupIntercom,
  signinIntercom,
  hideIntercom,
  showIntercom,
};
