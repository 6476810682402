import {
  Box,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { KYDEvent, KYDEventSummary } from "@common/interfaces/KYDEvent";
import { FaChevronRight, FaPlus } from "react-icons/fa";

const MobileEventCard = ({ summary }: { summary: KYDEventSummary }) => {
  return (
    <Stack
      direction={"row"}
      w="full"
      flexGrow={1}
      as={Link}
      href={summary.link}
      target="_blank"
      alignItems={"center"}
    >
      <Box
        background={"#ddd"}
        overflow={"hidden"}
        width={"80px"}
        height={"80px"}
        minHeight={"80px"}
        maxWidth={"80px"}
        flexGrow={1}
        rounded="lg"
        roundedBottom={"lg"}
        display="flex"
        alignItems={"flex-end"}
        flexShrink={0}
      >
        <Image
          cursor={"pointer"}
          fit={"cover"}
          width={"80px"}
          height={"80px"}
          w="100%"
          onClick={() => {
            // setModalType(ModalType.LIGHTBOX, kydEvent.image);
          }}
          src={summary.image}
        />
      </Box>
      <Stack direction={"row"} spacing={0} alignItems={"center"} flexGrow={1}>
        <VStack
          justifyContent={"space-between"}
          alignContent={"space-between"}
          py={{ base: 2, md: "2" }}
          px={{ base: 4, md: "2" }}
          w="100%"
          roundedBottom={{ base: "none", md: "lg" }}
        >
          <Text w="full" fontSize="md" noOfLines={1} fontWeight={"bold"}>
            {summary.name}
          </Text>
          <Text w="full" fontSize="sm" noOfLines={1}>
            {summary.display_start_at}
          </Text>
          <Text w="full" fontSize="sm" noOfLines={1} opacity={0.6}>
            {summary.venue.name}
          </Text>
        </VStack>
        <IconButton
          display={{ base: "flex", md: "none" }}
          icon={<FaChevronRight fontSize="1.25rem" />}
          variant="ghost"
          _hover={{
            background: "transparent",
          }}
          aria-label="Go to event"
        />
      </Stack>
    </Stack>
  );
};

const EventCard = ({ summary }: { summary: KYDEventSummary }) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  if (!summary) return null;

  return isMobile ? (
    <MobileEventCard summary={summary} />
  ) : (
    <Stack
      as={Link}
      href={summary.link}
      maxWidth={"20%"}
      direction={"row"}
      flexWrap={"wrap"}
      spacing={0}
      rounded={"lg"}
      height={"100%"}
      shadow={"md"}
    >
      <Box
        width={"100%"}
        flexGrow={1}
        rounded="lg"
        roundedBottom={{ base: "lg", md: "none" }}
        display="flex"
        background="#efefef"
        h="160px"
        w="160px"
        overflow={"hidden"}
      >
        <Image
          cursor={"pointer"}
          fit={"cover"}
          w="100%"
          h="100%"
          src={summary.image}
          display={{ base: "inherit", md: "table-cell" }}
        />
      </Box>
      <Stack spacing={0} flexGrow={1} alignItems={"center"} p={2}>
        <VStack
          flexGrow={{ base: 1, md: 0 }}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          alignContent={"space-between"}
          flexWrap={"wrap"}
          w="100%"
        >
          <Text w="full" fontSize="md" noOfLines={1} fontWeight={"bold"}>
            {summary.name}
          </Text>
          <Text w="full" fontSize="sm" noOfLines={1}>
            {summary.display_start_at}
          </Text>
          <Text w="full" fontSize="sm" noOfLines={1} opacity={0.6}>
            {summary.venue.name}
          </Text>
        </VStack>
        <IconButton
          display={{ base: "flex", md: "none" }}
          icon={<FaChevronRight fontSize="1.25rem" />}
          variant="ghost"
          _hover={{
            background: "transparent",
          }}
          aria-label="Go to event"
        />
      </Stack>
    </Stack>
  );
};

const RelatedShows = ({
  relatedShows = [],
}: {
  relatedShows?: KYDEvent["related_shows"];
}) => {
  return (
    <Stack color={{ base: "white", md: "black" }}>
      <Text px={[2, null, 5]} fontWeight={"bold"} fontSize="xl">
        Similar events
      </Text>
      <Stack
        align={"flex-start"}
        spacing={3}
        direction={{ base: "column", md: "row" }}
        display="flex"
        justifyContent="flex-start"
        p={4}
      >
        {relatedShows.map((r) => (
          <EventCard summary={r} />
        ))}
      </Stack>
    </Stack>
  );
};

export default RelatedShows;
